import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

interface ThanksModalProps {
  show: boolean;
  ToggleModal: () => void;
  bookingId: string | undefined; // Adjust type if bookingId is not a string
}

const ThanksModal: React.FC<ThanksModalProps> = (props) => {
  const history = useNavigate();

  return (
    <>
      <Modal show={props.show} onHide={props.ToggleModal} className="thankyou">
        <Button
          onClick={() => {
            props.ToggleModal();
            history("/profile/booking", { replace: true });
          }}
          variant={"link"}
          className={`text-danger text-right`}
        >
          <FontAwesomeIcon size={"2x"} icon={faTimesCircle} />
        </Button>
        <Modal.Body>
          <h1>
            <strong>
              <i className="fas fa-thumbs-up"></i> <br />
              {"!Thank You!"}
            </strong>
            <br /> <span>{"Congratulations your booking is confirmed"}</span>
          </h1>
          <p>{"your booking id #" + props.bookingId}</p>
        </Modal.Body>
      </Modal>
      {/* <div className="modal modal-wrapper" id="thankyou">
        <div className="modal-dialog modal-dialog-centered thankyou">
          <div className="modal-content">

            <div className="modal-body">
              <h1>
                <strong>
                  <i className="fas fa-thumbs-up"></i> <br />
                  !Thank You!
                </strong>
                <br /> <span>Congratulations your booking is confirmed</span>
              </h1>
              <p>your booking id 121520</p>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default ThanksModal;
