import React from "react";
import { Spinner } from "react-bootstrap";

const PageLoading = () => {
  return (
    <>
      <div className="page-loading-container">
        <Spinner animation="border" style={{ color: "#1e5698" }} />
      </div>
    </>
  );
};

export default PageLoading;
