import React from "react";
import "../../App.css";

const PageNotFound: React.FC = () => {
  return (
    <div className={"pageNotFoundCard"}>
      <h1 className={"shadowTextHeading otto"}>404</h1>
      <h4
        style={{
          fontWeight: "bold",
          color: "rgb(51 51 51)",
          padding: "15px 5px",
        }}
      >
        {"Page Not Found"}
      </h4>
    </div>
  );
};

export default PageNotFound;
