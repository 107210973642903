import React from "react";

// Define the type for props
interface NoDataPageProps {
  title: string;
}

const NoDataPage: React.FC<NoDataPageProps> = (props) => {
  return (
    <div className="page-loading-container">
      <h2>{props.title}</h2>
    </div>
  );
};

export default NoDataPage;
