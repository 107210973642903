import React, { useState, useRef, ChangeEvent, FormEvent } from "react";
import {
  faPlus,
  faTimesCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Alert, Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import ApiRequest from "../../services/ApiRequest.service";
import { APIURLS } from "../../services/APIURLS";
import { removeHtml } from "../../utils/helpers";

interface ListPropertyModalProps {
  requestId: string;
  show: boolean;
  toggleModal: () => void;
  onSuccessCallback: (res: any) => void;
}

const ListPropertyModal: React.FC<ListPropertyModalProps> = (props) => {
  const { requestId, show, toggleModal, onSuccessCallback } = props;
  const [propertyImages, setPropertyImages] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const multipleFileRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>("");

  const onChangeMultipleFile = (e: ChangeEvent<HTMLInputElement>) => {
    let target = e.target;
    if (target && target.files) {
      let files = Array.from(target.files);
      if (files.length > 0) {
        setPropertyImages(files);
      } else {
        setPropertyImages([]);
        if (multipleFileRef.current) {
          multipleFileRef.current.value = "";
        }
      }
    }
  };

  const onRemoveFile = (imgIndex: number) => {
    let images = propertyImages.filter((_value, index) => imgIndex !== index);
    setPropertyImages(images);
  };

  const handleForm = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (propertyImages.length > 15) {
      return;
    }

    setError("");
    let formData = new FormData(e.currentTarget);
    formData.append("id", requestId);

    ApiRequest({
      url: APIURLS.SUBMIT_PROPERTY_DETAILS,
      values: formData,
      loading: setLoading,
      formData: true,
      onSuccessCallback: (res) => {
        setPropertyImages([]);
        if (multipleFileRef.current) {
          multipleFileRef.current.value = "";
        }

        if (fileRef.current) {
          fileRef.current.value = "";
        }
        onSuccessCallback(res);
      },
      onError: (res) => {
        setError(res.msg);
      },
    });
  };

  return (
    <Modal centered show={show}>
      <Button
        onClick={() => toggleModal()}
        variant={"link"}
        className="text-danger text-right"
      >
        <FontAwesomeIcon size={"2x"} icon={faTimesCircle} />
      </Button>

      <div className="p-3">
        {error && <Alert variant={"danger"}>{removeHtml(error)}</Alert>}

        <Form onSubmit={handleForm}>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Document</Form.Label>
            <Form.Control ref={fileRef} type="file" name="agreement" />
          </Form.Group>
          <Form.Label>
            Property Images <small> (max files 15)</small>
          </Form.Label>
          <div className="multiUploadBlock">
            {propertyImages.length > 0 && (
              <>
                {propertyImages.map((img, index) => (
                  <div key={index} className="singleImgView">
                    <img
                      style={{ width: "100%" }}
                      src={URL.createObjectURL(img)}
                      alt=""
                    />
                    <div
                      onClick={() => onRemoveFile(index)}
                      className="singleImgRemove"
                    >
                      <FontAwesomeIcon
                        className="text-danger"
                        size={"1x"}
                        icon={faTrashAlt}
                      />
                    </div>
                  </div>
                ))}
                <div className="singleImgView border d-flex align-items-center justify-content-center">
                  <label htmlFor="propertyImages" className="m-0">
                    <FontAwesomeIcon size={"1x"} icon={faPlus} />
                  </label>
                </div>
              </>
            )}

            {propertyImages.length === 0 && (
              <label className="imageDropzone" htmlFor="propertyImages">
                Choose Images
              </label>
            )}

            <input
              ref={multipleFileRef}
              accept="image/*"
              onChange={onChangeMultipleFile}
              multiple
              hidden
              type="file"
              name="propertyImages[]"
              id="propertyImages"
            />
          </div>
          {propertyImages.length > 15 && (
            <div className="invalid-feedback d-block">
              {"You can upload only 15 Images"}
            </div>
          )}

          <Button
            type="submit"
            disabled={loading}
            className="submit-theme-btn mt-3"
          >
            Submit
          </Button>
        </Form>
      </div>
    </Modal>
  );
};

export default ListPropertyModal;
