import moment from "moment";

export const GETPARSEDATA = (strDate: Date): string => {
  let date = strDate;
  let dd: string | number = date.getDate();
  let mm: string | number = date.getMonth() + 1; // January is 0!

  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = new Date(`${yyyy}-${mm}-${dd}`);
  return `${dd}-${mm}-${yyyy}`;
};

export const GETPARSEDATA_YY = (strDate: Date): string => {
  let date = strDate;
  let dd: string | number = date.getDate();
  let mm: string | number = date.getMonth() + 1; // January is 0!

  const yyyy = date.getFullYear();
  if (dd < 10) {
    dd = "0" + dd;
  }
  if (mm < 10) {
    mm = "0" + mm;
  }
  date = new Date(`${yyyy}-${mm}-${dd}`);
  return `${yyyy}-${mm}-${dd}`;
};

export const amenitiesFind = (
  findAmenityID: number,
  amenitiesArray: number[]
): number => {
  const find = amenitiesArray.find((amenity) => amenity === findAmenityID);
  return find !== undefined ? 1 : 0;
};

export const PoliciesFind = (
  findPolicyID: number,
  PoliciesArray: number[]
): number => {
  const find = PoliciesArray.find((policy) => policy === findPolicyID);
  return find !== undefined ? 1 : 0;
};

export const GET_TOTAL_ROOMS = ({
  roomCategories,
  floorRooms,
  roomCategory,
}: {
  roomCategories: { id: number }[];
  floorRooms: {
    [key: string]: any;
    rooms: { room_category: number; room_filled: boolean }[];
  }[];
  roomCategory?: number;
}): { totalRooms: number; totalFilled: number } => {
  let totalRooms = 0;
  let totalFilled = 0;

  floorRooms.forEach((floor) => {
    let floorChecked = false;
    if (roomCategory) {
      const checked = floor["cat_checked_" + roomCategory];
      if (checked) {
        floorChecked = true;
      }
    } else {
      roomCategories.forEach((category) => {
        const checked = floor["cat_checked_" + category.id];
        if (checked) {
          floorChecked = true;
        }
      });
    }
    // Enabled floors
    if (floorChecked) {
      floor.rooms.forEach((room) => {
        if (roomCategory) {
          if (room.room_category === roomCategory) {
            // Increase total room count
            totalRooms++;
            if (room.room_filled) {
              // Increase filled count
              totalFilled++;
            }
          }
        } else {
          // Increase total room count
          totalRooms++;
          if (room.room_filled) {
            // Increase filled count
            totalFilled++;
          }
        }
      });
    }
  });

  return {
    totalRooms,
    totalFilled,
  };
};

export const GET_DATE_ROOM_COUNTS = ({
  rooms,
}: {
  rooms: { checkin_date: string; rooms: number }[];
}): { todayRoomsBooking: number } => {
  const todayDate = moment().utcOffset("+05:30").format("YYYY-MM-DD");
  let todayRoomsBooking = 0;

  rooms.forEach((room) => {
    if (todayDate === room.checkin_date) {
      todayRoomsBooking += room.rooms;
    }
  });

  return {
    todayRoomsBooking,
  };
};

export const PadNumber = (num: number, size: number): string => {
  let s = num + "";
  while (s.length < size) s = "0" + s;
  return s;
};

export const openMap = (lat: number, lng: number, label: string): void => {
  // var scheme = Platform.OS === 'ios' ? 'maps:' : 'geo:';
  // var url = scheme + `${lat},${lng}`;
  // Linking.openURL(url);
  // const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  // const latLng = `${lat},${lng}`;
  // const url = Platform.select({
  //   ios: `${scheme}${label}@${latLng}`,
  //   android: `${scheme}${latLng}(${label})`
  // });
  // Linking.openURL(url);
};

export const openMapByPlaceID = (placeID: string): void => {
  // const scheme = Platform.select({ ios: 'maps:0,0?q=', android: 'geo:0,0?q=' });
  // const latLng = `${lat},${lng}`;
  // const url = Platform.select({
  //   ios: `${scheme}${label}@${latLng}`,
  //   android: `${scheme}${latLng}(${label})`
  // });
  // let url = `https://www.google.com/maps/place/?q=place_id:${placeID}`;
  // Linking.openURL(url);
};

export const RatingText = (rating: number): string => {
  let ratingString = "";
  if (rating > 0 && rating < 1) {
    ratingString = "Very Bad";
  } else if (rating >= 1 && rating < 2) {
    ratingString = "Bad";
  } else if (rating >= 2 && rating < 3) {
    ratingString = "Good";
  } else if (rating >= 3 && rating < 4) {
    ratingString = "Best";
  } else if (rating >= 4 && rating < 5) {
    ratingString = "Excellent";
  }
  return ratingString;
};
