import React, { useEffect, useState, useCallback, useReducer } from "react";
import { useParams, Link } from "react-router-dom";
import { CONSTANTS } from "../../utils/Constants";
import { PadNumber } from "../../utils/helpers";
import {
  HOTEL_REDUCER,
  initialStateHotel,
  UPDATE_ROOM_DATA,
} from "../../Reducers/HotelView/HotelReducer";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import PageLoading from "../../components/page-loading/PageLoading";
import RoomImageSlider from "../../components/hotel-view/RoomImageSlider";
import Api from "../../services/api.service";
import HotelDescription from "../../components/hotel-view/HotelDescription";
import AmenitiesWithFilter from "../../components/hotel-view/AmenitiesWithFilter";
import SelectRoomCategorySection from "../../components/hotel-view/SelectRoomCategorySection";
import HotelRatingAndReview from "../../components/hotel-view/HotelRatingAndReview";
import HotelPolicies from "../../components/hotel-view/HotelPolicies";
import BookingOptionsBlock from "../../components/hotel-view/BookingOptionsBlock";

interface HotelDetails {
  id: any;
  hotel_name: string;
  address: string;
  description: string;
  couple_friendly: string;
  room_images: string[];
  hotel_images: string[];
  amenities: string[];
  check_in_time: string;
  check_out_time: string;
  master_image: string;
  vendor_id: string;
  policy_rules: string;
  settings: {
    room_2_charges: number;
    room_3_charges: number;
    room_4_charges: number;
    room_5_charges: number;
    room_6_charges: number;
    night_2_charges: number;
    night_4_charges: number;
    night_8_charges: number;
    guest_2_charges: number;
    guest_3_charges: number;
  };
}

interface RoomData {
  id: number;
  actual_price: number;
  cut_off_price: number;
  room_category: string;
  room_images: { image: string }[];
  amenities: string[];
}

interface MatchParams {
  id: string;
  roomId: string;
}

const HotelView = () => {
  const [loading, setLoading] = useState(true);
  const [hotelDetails, setHotelDetails] = useState<HotelDetails | null>(null);
  const [roomsData, setRoomsData] = useState<RoomData[]>([]);
  const params = useParams();

  const { id, roomId } = params;

  const getHotelDetails = useCallback(async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("hotel_id", id ?? "");
    try {
      const res = await Api.post("hotel/get_hotel_details", formData);
      const data = res.data;
      if (data.status === "success") {
        setHotelDetails(data.data);
        setLoading(false);
        setRoomsData(data.rooms);

        const roomCharge = {
          room_2_charges: data.settings.room_2_charges,
          room_3_charges: data.settings.room_3_charges,
          room_4_charges: data.settings.room_4_charges,
          room_5_charges: data.settings.room_5_charges,
          room_6_charges: data.settings.room_6_charges,
        };

        const nightCharge = {
          night_2_charges: data.settings.night_2_charges,
          night_4_charges: data.settings.night_4_charges,
          night_8_charges: data.settings.night_8_charges,
        };

        const guestCharge = {
          guest_2_charges: data.settings.guest_2_charges,
          guest_3_charges: data.settings.guest_3_charges,
        };

        const copyInitialStateHotel = {
          ...data.data,
          ...initialStateHotel,
        };
        copyInitialStateHotel.roomChargeSettings = roomCharge;
        copyInitialStateHotel.nightChargeSettings = nightCharge;
        copyInitialStateHotel.guestChargeSettings = guestCharge;

        hotelDispatch({
          type: UPDATE_ROOM_DATA,
          payload: copyInitialStateHotel,
        });
      }
    } catch (error) {
      console.log("Error");
    }
  }, [id]);

  const [hotelState, hotelDispatch] = useReducer(
    HOTEL_REDUCER,
    initialStateHotel
  );

  useEffect(() => {
    setLoading(true);

    const initialLoad = async () => {
      await getHotelDetails();
    };

    initialLoad();
  }, [getHotelDetails]);

  if (loading) {
    return <PageLoading />;
  }

  return (
    <>
      <HotelContext.Provider
        value={{
          hotelState: hotelState,
          hotelDispatch: hotelDispatch,
        }}
      >
        <div className="page-wrapper hotelview-page wow slideInUp">
          {hotelDetails && (
            <>
              <RoomImageSlider
                images={hotelDetails.room_images}
                hotelImages={hotelDetails.hotel_images}
              />
              <div className="hotel-full-details-wrapper wow slideInDown">
                <div className="container">
                  <div className="row">
                    <div className="col-md-8 border-right border-left wow slideInLeft">
                      <div className="hotel-details-wrapper-left">
                        <h1 className="details-heading">
                          <img
                            alt=""
                            src={CONSTANTS.LOGO}
                            style={{ width: 24 }}
                            className={"logoBrandingImage"}
                          />{" "}
                          {PadNumber(hotelDetails.id, 4)}{" "}
                          {hotelDetails.hotel_name}
                        </h1>
                        <p>
                          <span>{hotelDetails.address}</span>
                        </p>
                        {Number(hotelDetails.couple_friendly) === 1 && (
                          <div style={{}}>
                            <span className="badge badge-warning">
                              {"Couple Friendly"}
                            </span>
                          </div>
                        )}
                        <hr />
                        <HotelDescription
                          description={hotelDetails.description}
                        />

                        <AmenitiesWithFilter
                          roomAmenity={hotelDetails.amenities}
                        />

                        {/*  Rooms Section */}

                        <SelectRoomCategorySection
                          hoteldata={hotelDetails}
                          rooms={roomsData}
                          selected_room={roomId}
                        />

                        {/* Rating And Reviews */}

                        <HotelRatingAndReview />

                        <h2 className="details-sub-heading">Hotel policies</h2>
                        <div className="hotel-policies">
                          <ul className="checkinout-box">
                            <li>
                              Check-In <span>{hotelDetails.check_in_time}</span>
                            </li>
                            <li>
                              Check-Out{" "}
                              <span>{hotelDetails.check_out_time}</span>
                            </li>
                          </ul>
                          {/* Policy List */}
                          <HotelPolicies hoteldetails={hotelDetails} />
                          <Link to="/guest-policy">View Guest Policy</Link>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <BookingOptionsBlock />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </HotelContext.Provider>
    </>
  );
};

export default HotelView;
