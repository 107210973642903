import React from "react";
import { connect, ConnectedProps } from "react-redux";
import { APIURLS } from "../../services/APIURLS";
// import { RootState } from "../../store";

type ProfileSideSectionProps = ConnectedProps<typeof connector>;

const ProfileSideSection: React.FC<ProfileSideSectionProps> = (props) => {
  const { userData } = props;

  const IMAGE_URL_USER_PROFILE =
    APIURLS.IMAGE_URL_USER_PROFILE + userData.profile_pic;

  return (
    <div className="profile-view-wrapper">
      <div className="profile-with-bg">
        <div className="profile-pic">
          <i className="fas fa-pencil-alt"></i>
          {userData.profile_pic !== "" ? (
            <img alt="" className="img-fluid" src={IMAGE_URL_USER_PROFILE} />
          ) : (
            <img
              alt=""
              className="img-fluid"
              src={
                process.env.PUBLIC_URL + "/assets/images/default-user-img.png"
              }
            />
          )}
        </div>
        <h4>{userData.full_name}</h4>
      </div>
      <ul className="profile-emptylink">
        {/* <li>
              <a href="#">
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          "/assets/images/pluse-icons.png"
                      }
                  />
              </a>
              <h6>
                  Add profile photo
                  <span>
                      This is the first thing people see, so show them
                      your best side!
                  </span>
              </h6>
          </li>
          <li>
              <a href="#">
                  <img
                      src={
                          process.env.PUBLIC_URL +
                          "/assets/images/pluse-icons.png"
                      }
                  />
              </a>
              <h6>
                  Add a display name
                  <span>
                      This can be updated as often as you want and is
                      shown with your reviews.
                  </span>
              </h6>
          </li> */}
      </ul>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);

export default connector(ProfileSideSection);
