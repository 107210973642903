import React, { useEffect, useReducer, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import {
  FILTER_REDUCER,
  initialState,
  UPDATE_FILTER,
} from "../../Reducers/FilterView/FilterReducer";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import { MinMaxPrice } from "../../helper/searchHelper";
import PageLoading from "../../components/page-loading/PageLoading";
import FilterSection from "../../components/filter/FilterSection";
import FiltersResult from "../../components/filter/FiltersResult";

// Define the type for position
interface Position {
  latitude: number;
  longitude: number;
}

// Define the HotelByCities component
const HotelByCities = () => {
  const params = useParams();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [state, dispatch] = useReducer(FILTER_REDUCER, initialState);

  const loadAmenities = useCallback(async () => {
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      const res = await Api.post(APIURLS.GETALLAMENITIES, formData);
      const responseData = res.data;
      if (responseData.status === "success") {
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            amenities: responseData.data,
          },
        });
      }
    } catch (error) {
      // Handle error if needed
    }
  }, []);

  const getHotels = useCallback(async () => {
    const formData = new FormData();
    const token = await localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("city", params.id ?? "");

    let position: Position = { latitude: 0, longitude: 0 };

    navigator.geolocation.getCurrentPosition(
      (currentPosition) => {
        position = {
          latitude: currentPosition.coords.latitude,
          longitude: currentPosition.coords.longitude,
        };
      },
      (error) => {
        // Handle geolocation error if needed
        console.error(error);
      }
    );

    try {
      const res = await Api.post(APIURLS.GETCITYHOTELS, formData);
      const data = res.data;
      if (data.status === "success") {
        setDataLoading(false);
        const allHotels = data.data;
        // Calculate Min Max Price
        const minMaxPrice = MinMaxPrice(allHotels);
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            minPrice: Number(minMaxPrice.min),
            maxPrice: Number(minMaxPrice.max),
            priceValues: [Number(minMaxPrice.min), Number(minMaxPrice.max)],
            originalHotelsList: allHotels,
            filtersHotels: allHotels,
            position: position,
            orderByNearest: allHotels,
          },
        });
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      // Handle error if needed
      console.error(error);
    }
  }, [params.id]);

  useEffect(() => {
    loadAmenities();
    getHotels();
  }, [loadAmenities, getHotels]);

  if (dataLoading) {
    return <PageLoading />;
  } else {
    return (
      <FilterContext.Provider
        value={{
          state: state,
          dispatch: dispatch,
        }}
      >
        <section className="whole-search-wrapper my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3 filter-border-right">
                <FilterSection />
              </div>
              <div className="col-md-8 col-lg-8">
                <div className="short-filter-wrapper">
                  <h1>Silver STAYS</h1>
                </div>
                <FiltersResult />
              </div>
            </div>
          </div>
        </section>
      </FilterContext.Provider>
    );
  }
};

export default HotelByCities;
