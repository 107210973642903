import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";
import "./Loader.css";

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux;

const OnlinePaymentPage: React.FC<Props> = ({ userData }) => {
  const location = useLocation();

  const url = `https://silverstays.in/payment/ccavRequestHandler.php`;
  const redirectUrl = `https://silverstays.in/payment/response-handler-web.php`;
  const cancellUrl = `https://silverstays.in/payment/response-handler-web.php`;

  const paymentData = location.state;

  useEffect(() => {
    setTimeout(() => {
      const form = document.getElementById(
        "initiatePayment"
      ) as HTMLFormElement;
      form.submit();
    }, 1000);
  }, []);

  return (
    <>
      <div className="center_loading_container">
        <div className="loader"></div>
        <h4>Please wait...</h4>
      </div>
      <div style={{ display: "none" }}>
        <form id="initiatePayment" method="POST" action={url}>
          <input name="tid" value={paymentData.txn_id} />
          <input name="merchant_id" value="283307" />
          <input name="order_id" value={paymentData.booking_id} />
          <input name="amount" value={paymentData.amount} />
          <input name="currency" value="INR" />
          <input name="redirect_url" value={redirectUrl} />
          <input name="cancel_url" value={cancellUrl} />
          <input name="language" value="EN" />
          <input name="billing_country" value="india" />
          <input name="contact_number" value={userData.mobile} />
          <input name="email_id" value={userData.email} />
        </form>
      </div>
    </>
  );
};

export default connector(OnlinePaymentPage);
