import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Api from "../../../../services/api.service";

interface City {
  city_id: string;
  city_name: string;
}

const HeaderCitiesNavbar: React.FC = () => {
  const [CitiesList, setCitiesList] = useState<City[]>([]);
  const [DataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    getCities();
  }, []);

  const getCities = async () => {
    setDataLoading(true);
    const formdata = new FormData();
    try {
      const res = await Api.post("home/get_all_cities", formdata);
      const data = res.data["data"];
      if (res.data["status"] === "success") {
        setCitiesList(data);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    } finally {
      setDataLoading(false);
    }
  };

  return (
    <nav className="navigation-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            <button id="nav-button">
              <i className="fas fa-bars"></i>
            </button>
            <ul className="navigation-menu">
              {CitiesList.map((city, index) => (
                <li key={index.toString()}>
                  <Link to={`/hotel-by-city/${city.city_id}`}>
                    {city.city_name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default HeaderCitiesNavbar;
