import React from "react";
import SearchBar from "../search-bar/SearchBar";

const HomeSearchingBlock: React.FC = () => {
  return (
    <section className="wow slideInUp">
      <div className="container-fluid search-wrapper-bg">
        <div className="row justify-content-center">
          <h1
            className="wow slideInRight"
            data-wow-duration="1500ms"
            data-wow-delay="300ms"
          >
            Find hotels at the lowest prices
          </h1>
          <div
            className="col-sm-9  wow slideInLeft"
            data-wow-duration="1500ms"
            data-wow-delay="300ms"
            style={{ zIndex: 3 }}
          >
            <SearchBar />
          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeSearchingBlock;
