import React from "react";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";
import { amenitiesFind } from "../../helper/helper";

interface Category {
  id: number;
  category: string;
}

interface FilterState {
  categories: Category[];
  selectedCategories: number[];
}

interface FilterAction {
  type: string;
  payload: any;
}

const RoomCategoryFilter: React.FC = () => {
  const { state, dispatch } = React.useContext(FilterContext) as {
    state: FilterState;
    dispatch: React.Dispatch<FilterAction>;
  };

  if (state.categories.length === 0) {
    return null;
  }

  const addCategory = (list: Category, find: boolean) => {
    let newUpdate = [...state.selectedCategories];
    if (find) {
      newUpdate = newUpdate.filter((item) => Number(item) !== Number(list.id));
    } else {
      newUpdate.push(Number(list.id));
    }
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        selectedCategories: newUpdate,
      },
    });
  };

  return (
    <section className="sidebar-box">
      <h6>Room Category</h6>
      <div className="form-group">
        {state.categories.map((list, index) => {
          const find =
            state.selectedCategories.length > 0
              ? amenitiesFind(Number(list.id), state.selectedCategories)
              : false;
          return (
            <label key={index.toString()}>
              <input
                checked={find as any}
                onClick={() => addCategory(list, find as any)}
                className="checkbox"
                type="checkbox"
              />
              <span>{list.category}</span>
            </label>
          );
        })}
      </div>
    </section>
  );
};

export default RoomCategoryFilter;
