import React, { useState, FormEvent } from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";

import ForgotModal from "./ForgotModal";
import ResetOtpVerify from "./ResetOtpVerify";
import ResetPasswordModal from "./ResetPasswordModal";
import Api from "../../services/api.service";
import { userThunkLogin } from "../../redux-store/actions/userAction";
import LoginWithSocial from "../../components/login-with-social/LoginWithSocial";

interface LoginSignupModalProps extends PropsFromRedux {
  show: boolean;
  ToggleModal: () => void;
  ToggleVerifyModal: () => void;
}

const LoginSignupModal: React.FC<LoginSignupModalProps> = (props) => {
  const [form, setForm] = useState<"login" | "sign-up">("login");
  const [FormErrors, setFormErrors] = useState<string>("");
  const [ValidationFormErrors, setValidationFormErrors] = useState<any>("");
  const [FormSuccess, setFormSuccess] = useState<string>("");
  const [FormLoading, setFormLoading] = useState<boolean>(false);
  const [otpModal, setOtpModal] = useState<boolean>(false);
  const [ResetModal, setResetModal] = useState<boolean>(false);

  const [modalShow, setModalShow] = useState<boolean>(false);
  const ToggleModal = () => {
    setModalShow(!modalShow);
  };
  const ToggleVerifyModal = () => {
    setOtpModal(!otpModal);
  };
  const ToggleResetModal = () => {
    setResetModal(!ResetModal);
  };

  const handleSignUpSubmit = (e: FormEvent<HTMLFormElement>) => {
    setFormErrors("");
    setFormSuccess("");
    setValidationFormErrors("");
    setFormLoading(true);
    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    const fname = e.currentTarget.firstname.value;
    const lname = e.currentTarget.lastname.value;
    const mobile = e.currentTarget.mobile.value;

    if (fname === "" || lname === "") {
      setValidationFormErrors({
        firstname: "First Name Field is required",
        lastname: "Last Name Field is required",
      });
      setFormLoading(false);
      return;
    }

    const fullname = `${fname} ${lname}`;
    formdata.append("full_name", fullname);
    formdata.append("email", e.currentTarget.email.value);
    formdata.append("mobile", e.currentTarget.mobile.value);
    formdata.append("pass", e.currentTarget.pass.value);
    formdata.append("confirm_pass", e.currentTarget.cfirmpassword.value);

    Api.post("user_signup", formdata)
      .then((res) => {
        setFormLoading(false);
        if (res.data.status === "success") {
          localStorage.setItem("usermobile", mobile);
          props.ToggleModal();
          props.ToggleVerifyModal();
        } else if (res.data.title === "form Error") {
          setValidationFormErrors(res.data.formErrors);
        } else {
          setFormErrors(res.data.msg);
        }
      })
      .catch(() => {
        setFormLoading(false);
      });
  };

  const handleLoginSubmit = (e: FormEvent<HTMLFormElement>) => {
    setFormErrors("");
    setFormSuccess("");
    setValidationFormErrors("");
    setFormLoading(true);
    e.preventDefault();
    const formdata = new FormData(e.currentTarget);
    formdata.append("mobile", e.currentTarget.mobile.value);
    formdata.append("pass", e.currentTarget.pass.value);

    Api.post("user_login", formdata)
      .then((res) => {
        setFormLoading(false);
        const data = res.data;
        if (data.status === "success") {
          const usertoken = `${data.token_id}:${data.token}`;
          props.login(usertoken);
          props.ToggleModal();
        } else if (res.data.title === "form Error") {
          setValidationFormErrors(res.data.formErrors);
        } else {
          setFormErrors(res.data.msg);
        }
      })
      .catch(() => {
        setFormLoading(false);
      });
  };

  return (
    <>
      <Modal
        animation={true}
        centered
        className="modal-wrapper"
        size="lg"
        show={props.show}
        onHide={props.ToggleModal}
      >
        <div>
          <button type="button" className="close" onClick={props.ToggleModal}>
            &times;
          </button>
        </div>

        <ul className="nav nav-tabs modal-tabs">
          <li className="nav-item">
            <a
              className={`nav-link ${form === "login" ? "active" : ""}`}
              data-toggle="tab"
              href="#Login"
              onClick={(e) => {
                e.preventDefault();
                setForm("login");
              }}
            >
              Login
            </a>
          </li>
          <li className="nav-item">
            <a
              className={`nav-link ${form === "sign-up" ? "active" : ""}`}
              data-toggle="tab"
              href="#SignUp"
              onClick={(e) => {
                e.preventDefault();
                setForm("sign-up");
              }}
            >
              SignUp
            </a>
          </li>
        </ul>
        <div className="modal-body">
          <div className="tab-content">
            <div
              id="Login"
              className={`Login tab-pane ${
                form === "login" ? "active" : "fade"
              }`}
            >
              <form onSubmit={handleLoginSubmit} className="form login-form">
                {FormSuccess && <Alert variant="success">{FormSuccess}</Alert>}
                {FormErrors && <Alert variant="danger">{FormErrors}</Alert>}
                <div className="form-group">
                  <label>Email & number</label>
                  <input
                    className="form-control"
                    type="text"
                    name="mobile"
                    placeholder=""
                  />
                  <div className="invalid-feedback d-block">
                    {ValidationFormErrors.mobile}
                  </div>
                </div>
                <div className="form-group">
                  <label>Password</label>
                  <input
                    className="form-control"
                    type="password"
                    name="pass"
                    placeholder=""
                  />
                  <div className="invalid-feedback d-block">
                    {ValidationFormErrors.pass}
                  </div>
                </div>

                <div className="form-group submit-btn">
                  <input type="submit" />
                </div>

                <div className="form-group account-link">
                  <span>
                    Not a member?
                    <a data-toggle="tab" href="#SignUp">
                      Sign up
                    </a>
                  </span>
                  <span>
                    Forgot password?
                    <Button
                      onClick={() => {
                        props.ToggleModal();
                        setModalShow(true);
                      }}
                      variant="link"
                    >
                      Click
                    </Button>
                  </span>
                </div>
                <LoginWithSocial {...props} />
              </form>
            </div>
            <div
              id="SignUp"
              className={`SignUp tab-pane ${
                form === "sign-up" ? "active" : "fade"
              }`}
            >
              <form onSubmit={handleSignUpSubmit} className="form signup-form">
                {FormSuccess && <Alert variant="success">{FormSuccess}</Alert>}
                {FormErrors && <Alert variant="danger">{FormErrors}</Alert>}
                <div className="row">
                  <div className="form-group col-sm-6">
                    <label>First Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="firstname"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.firstname}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Last Name</label>
                    <input
                      className="form-control"
                      type="text"
                      name="lastname"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.lastname}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Email</label>
                    <input
                      className="form-control"
                      type="text"
                      name="email"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.email}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Phone number</label>
                    <input
                      className="form-control"
                      type="text"
                      name="mobile"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.mobile}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="pass"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.pass}
                    </div>
                  </div>
                  <div className="form-group col-sm-6">
                    <label>Confirm password</label>
                    <input
                      className="form-control"
                      type="password"
                      name="cfirmpassword"
                      placeholder=""
                    />
                    <div className="invalid-feedback d-block">
                      {ValidationFormErrors.confirm_pass}
                    </div>
                  </div>
                </div>

                <div className="account-modalfooter">
                  <div className="form-group submit-btn">
                    <input type="submit" />
                  </div>

                  <div className="form-group account-link">
                    <span>
                      I have already account?
                      <a data-toggle="tab" href="#Login">
                        Login
                      </a>
                    </span>
                    <span>
                      Forgot password?
                      <a
                        id="forgotbtn2"
                        data-toggle="modal"
                        data-target="#forgotpassword"
                      >
                        Click
                      </a>
                    </span>
                  </div>
                  <LoginWithSocial {...props} />
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
      <ForgotModal
        show={modalShow}
        ToggleModal={ToggleModal}
        ToggleVerifyModal={ToggleVerifyModal}
      />
      <ResetOtpVerify
        show={otpModal}
        ToggleVerifyModal={ToggleVerifyModal}
        ToggleResetModal={ToggleResetModal}
      />
      <ResetPasswordModal
        show={ResetModal}
        ToggleResetModal={ToggleResetModal}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (token: string) => dispatch(userThunkLogin(token, "user")),
  };
};

const connector = connect(null, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginSignupModal);
