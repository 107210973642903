import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Api from "../../services/api.service";
import { CONSTANTS } from "../../utils/Constants";

interface Category {
  id: string;
  category: string;
  cate_image: string;
}

const HotelByCategory = () => {
  const [roomCategories, setRoomCategories] = useState<Category[]>([]);
  const [dataLoading, setDataLoading] = useState(false);

  useEffect(() => {
    getRoomCategories();
  }, []);

  const getRoomCategories = async () => {
    setRoomCategories([]);
    const formdata = new FormData();
    try {
      const res = await Api.post("home/browse_by_room_cate", formdata);
      const data = res.data;
      if (data.status === "success") {
        setRoomCategories(data.data);
        setDataLoading(false);
      } else {
        setRoomCategories([]);
        setDataLoading(false);
      }
    } catch (err) {
      setDataLoading(false);
    }
  };

  return (
    <div className="container wow slideInUp">
      <div className="row">
        <div className="col-sm-12">
          <div className="property-browse-wrapper ">
            <h1 className="title1">Browse by Category</h1>
            <div className="property-browse-wrapper-inner mt-3">
              <Row>
                {roomCategories.map((category, index) => (
                  <Col key={index.toString()} lg={4}>
                    <Link
                      to={`/hotel-by-category/${category.id}`}
                      state={{ category_name: category.category }}
                      className="property-browse-slides"
                    >
                      <div className="property-browse-imgbox">
                        <img
                          className="property-browse-img"
                          src={`${CONSTANTS.IMAGE_BASE_URL}/room_category/${category.cate_image}`}
                          alt={category.category}
                        />
                      </div>
                      <h2 className="browse-by-cate-heading">
                        {category.category}
                      </h2>
                    </Link>
                  </Col>
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HotelByCategory;
