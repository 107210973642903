import React, { useEffect, useState, FormEvent } from "react";
import { connect } from "react-redux";
import parse from "html-react-parser";
import Api from "../../services/api.service";
import { updateUserThunk } from "../../redux-store/actions/userAction";

interface UpdateProfileProps {
  updateUser: () => void;
}

interface ProfileData {
  full_name: string;
  email: string;
  mobile: string;
  address: string;
  nickname: string;
  dob: string;
}

const UpdateProfile: React.FC<UpdateProfileProps> = ({ updateUser }) => {
  const [profiledata, setProfileData] = useState<ProfileData>({
    full_name: "",
    email: "",
    mobile: "",
    address: "",
    nickname: "",
    dob: "",
  });

  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");

  useEffect(() => {
    getProfile();
  }, []);

  const getProfile = () => {
    var token = localStorage.getItem("token");
    const formdata = new FormData();
    formdata.append("token", token || "");
    Api.post("user/get_profile", formdata).then((res) => {
      if (res.data["status"] === "success") {
        const data: ProfileData = res.data.data;
        setProfileData(data);
      } else {
        // Handle error if needed
      }
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setError("");
    setSuccess("");
    const formdata = new FormData(e.currentTarget);
    var token = localStorage.getItem("token");
    formdata.append("token", token || "");

    Api.post("user/update_profile", formdata).then((res) => {
      if (res.data["status"] === "success") {
        setSuccess(res.data["msg"]);
        updateUser();
      } else {
        setError(res.data["msg"]);
      }
    });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setProfileData((prevProfileData) => ({
      ...prevProfileData,
      [name]: value,
    }));
  };

  const { full_name, email, mobile, address, nickname, dob } = profiledata;

  return (
    <div id="menu2" className="tab-pane">
      <form onSubmit={handleSubmit}>
        <div className="ms-border">
          <h2 className="tabs-heading" style={{ border: 0, paddingBottom: 0 }}>
            Edit Profile
          </h2>
          <button
            style={{ background: "orange", border: 0, borderRadius: 20 }}
            className="btn button-small btn-primary"
            type="submit"
          >
            Save
          </button>
        </div>
        {error && (
          <div className="alert alert-danger my-2" style={{ fontSize: 14 }}>
            {parse(error)}
          </div>
        )}
        {success && (
          <div className="alert alert-success my-2" style={{ fontSize: 14 }}>
            {parse(success)}
          </div>
        )}
        <ul className="edit-profile-info-list">
          <li>
            <i className="fas fa-user-circle"></i> <strong>Name :</strong>
            <input
              className="form-control"
              type="text"
              name="full_name"
              placeholder="Enter full name"
              value={full_name}
              onChange={handleChange}
            />
          </li>
          <li>
            <i className="fas fa-envelope"></i> <strong>Email :</strong>
            <input
              className="form-control"
              type="text"
              name="email"
              placeholder="Enter email"
              value={email}
              onChange={handleChange}
            />
          </li>
          <li>
            <i className="fas fa-phone-square-alt"></i>{" "}
            <strong>Mobile No. :</strong>
            <input
              className="form-control"
              type="text"
              name="mobile"
              value={mobile}
              disabled
            />
          </li>
          <li>
            <i className="fas fa-map-marker-alt"></i> <strong>Address :</strong>
            <input
              className="form-control"
              type="text"
              name="address"
              placeholder="Enter address"
              value={address}
              onChange={handleChange}
            />
          </li>
          <li>
            <i className="fas fa-user-circle"></i> <strong>Nickname :</strong>
            <input
              className="form-control"
              type="text"
              name="nickname"
              placeholder="Enter nickname"
              value={nickname}
              onChange={handleChange}
            />
          </li>
          <li>
            <i className="far fa-calendar-alt"></i> <strong>Birthday :</strong>
            <input
              className="form-control"
              type="date"
              name="dob"
              value={dob}
              onChange={handleChange}
            />
          </li>
        </ul>
      </form>
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateUser: () => dispatch(updateUserThunk()),
  };
};

export default connect(null, mapDispatchToProps)(UpdateProfile);
