import axios from "axios";

const Api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export default Api;

export const formDataHelper = (): FormData => {
  const formData = new FormData();
  const token = localStorage.getItem("token");
  if (token) {
    formData.append("token", token);
  }
  return formData;
};
