import React, { useState } from "react";
import moment from "moment";
import "./BookingRow.css";
import { Link } from "react-router-dom";
import GiveRating from "./GiveRating";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import { CONSTANTS } from "../../utils/Constants";
import { PadNumber } from "../../utils/helpers";
import RefundRequest from "./RefundRequest";

interface RoomData {
  room_details: {
    room_images: string;
    room_category: string;
  };
  room_price: string;
  price_drop: string;
  payment: string;
  status: string;
  cancelled_at: string;
  checkin_date: string;
  checkout_date: string;
  rooms: number;
  guest: number;
  hotel_id: number;
  hotel_name: string;
  order_id: string;
  id: string;
}

interface BookingRowProps {
  roomsdata: RoomData;
  refreshData: () => void;
}

const BookingRow: React.FC<BookingRowProps> = (props) => {
  const [removed, setRemoved] = useState(false);
  const [loading, setLoading] = useState(false);

  const roomdata = props.roomsdata;
  const roomImages = JSON.parse(roomdata.room_details.room_images);
  const finalPrice = roomdata.room_price;
  const priceDrop = roomdata.price_drop;
  const todayDate = moment().utcOffset("+05:30").format("YYYY-MM-DD");
  const roomBookPrice = parseFloat(priceDrop) + parseFloat(finalPrice);
  const paymentMode = roomdata.payment;
  const cancelledAt = moment(roomdata.cancelled_at).format("YYYY-MM-DD");

  let showRefundOption = false;
  if (paymentMode === "online" && roomdata.status === "cancelled") {
    const days = moment(todayDate).diff(moment(cancelledAt), "days");
    if (days <= 1) {
      showRefundOption = true;
    }
  }

  const cancelBooking = async (booking_id: string) => {
    const formData = new FormData();
    const token = await localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("booking_id", booking_id);
    setLoading(true);
    Api.post(APIURLS.CANCELBOOKING, formData)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.status === "success") {
          global.alert(data.msg);
          setRemoved(true);
          props.refreshData();
        } else {
          global.alert(data.msg);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  if (removed) {
    return null;
  }

  return (
    <div className={"singalbooking-view-box-outer"}>
      <div className="singalbooking-view-box">
        <div className="booking-view-img-wrapper">
          {roomImages[0].room_image === null ? (
            <img
              alt="base64String"
              className="img-fluid"
              src={
                process.env.PUBLIC_URL + "/assets/images/default_property.png"
              }
            />
          ) : (
            <img
              alt="base64String"
              className="img-fluid"
              src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${roomImages[0].room_image}`}
            />
          )}
        </div>
        <div className="booking-view-details-wrapper pr-1 pb-2">
          <span className="booking-hotel-name">
            <img
              alt="base64String"
              src={CONSTANTS.LOGO}
              className={"logoBrandingImage"}
            />
            <span style={{ fontSize: 16 }}>
              {PadNumber(roomdata.hotel_id, 4)}
            </span>{" "}
            {roomdata.hotel_name}
          </span>
          <div>
            <span
              style={{
                fontWeight: "700",
                textTransform: "uppercase",
                letterSpacing: 0.8,
                color: "#333333",
                flex: 1,
                flexWrap: "wrap",
              }}
            >
              {"Booking Id :"}
              <span style={{ fontWeight: "normal" }}>#{roomdata.order_id}</span>
            </span>
          </div>
          {/* <div
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                flexDirection: "row",
                backgroundColor: "#1E5698",
                padding: "0px 10px",
                alignItems: "center",
                borderRadius: 3,
              }}
            >
              <span style={{ color: "white", marginRight: 5, fontSize: 12 }}>
                {roomdata.user_rating}
              </span>
              <FontAwesomeIcon
                style={{ fontSize: 13, color: "white" }}
                icon={faStar}
              />
            </span>
            <span style={{ marginLeft: 10, color: "#333333" }}>Excellent</span>
          </div> */}
          <div
            className={"py-2"}
            style={{
              flexDirection: "row",
              alignItems: "center",
              display: "flex",
              fontSize: 14,
            }}
          >
            <span
              style={{
                fontWeight: "700",
                color: "#333333",
                letterSpacing: 0.2,
              }}
            >
              {moment(roomdata.checkin_date).format("ddd, D MMM")}
            </span>
            <span
              style={{
                fontWeight: "700",
                color: "#333333",
                letterSpacing: 0.2,
              }}
            >
              {moment(roomdata.checkout_date).format("ddd D MMM")}
            </span>
            <span
              style={{
                fontWeight: "700",
                color: "#333333",
                letterSpacing: 0.2,
                marginLeft: 20,
              }}
            >
              {roomdata.rooms + "Room," + roomdata.guest + "Guest"}
            </span>
            <span
              style={{
                marginLeft: 15,
                color: "#333",
              }}
            >
              {roomdata.room_details.room_category}
            </span>
          </div>

          <table className={"table table-borderless table-sm"}>
            <tbody>
              <tr>
                <td>{"Room price"}</td>
                <th>{"₹ " + roomBookPrice}</th>
              </tr>
              <tr>
                <td>{"Price Drop"}</td>
                <th>{"-₹ " + priceDrop}</th>
              </tr>
              {paymentMode === "online" && (
                <tr>
                  <td>{"Paid Amount"}</td>
                  <th>{"-₹ " + finalPrice}</th>
                </tr>
              )}
              <tr>
                <td>
                  {"Payable Amount"}
                  <small className={"text-muted"}>
                    {" (inclusive of all taxes)"}
                  </small>
                </td>
                <th>
                  {"₹"}
                  {paymentMode === "online" ? "0" : finalPrice}
                </th>
              </tr>
            </tbody>
          </table>

          {/* <h4 className="price">
                    <img
                        src={
                            process.env.PUBLIC_URL +
                            "/assets/images/rupee.png"
                        }
                    />
                    {1280}
                </h4>
                <a className="booking-status" href="#">
                    {"Confirmed"}
                </a>
                <a className="booking-location" href="#">
                    <i className="fas fa-map-marker-alt"></i> {"Map"}
                </a>
                <a className="booking-re-view" href="#">
                    <i className="fas fa-eye"></i> {"View"}
                </a> */}
        </div>
      </div>

      <div style={{ padding: "10px", backgroundColor: "rgb(242 246 251)" }}>
        <div>
          {roomdata.status === "booked" &&
            todayDate <= roomdata.checkin_date && (
              <button
                onClick={(e) => {
                  e.preventDefault();
                  const conf = global.confirm(
                    "Are you sure want to cancel the booking?"
                  );
                  if (conf) {
                    cancelBooking(roomdata.id);
                  }
                }}
                type="button"
                style={{ backgroundColor: "#F34D4D" }}
                className="btn btn-danger btn-sm mx-2"
              >
                {"Cancel Booking"}
              </button>
            )}
          {roomdata.status === "cancelled" && (
            <>
              {showRefundOption && <RefundRequest roomdata={roomdata as any} />}
              <span className="text-danger font-weight-bold">
                {"Booking Cancelled, "}
              </span>
            </>
          )}
          {roomdata.status === "checkedout" && (
            <div className={"mx-2 my-2"}>
              <GiveRating roomdata={roomdata as any} />
            </div>
          )}
          <Link className={"mr-2 "} to={"/cancellation-policy"}>
            {"Cancellation Policy,"}
          </Link>
          <Link className={""} to={"/guest-policy"}>
            {"Guest Policies"}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default BookingRow;
