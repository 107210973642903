import React, { useState, useEffect } from "react";
import parse from "html-react-parser";
import { connect, ConnectedProps } from "react-redux";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import PageLoading from "../../components/page-loading/PageLoading";

// Define the type for the settings state
interface Settings {
  term_and_condition: string;
}

// Define the type for the Redux state
interface RootState {
  userReducer: any; // Replace `any` with a more specific type if available
}

// Define the props based on the Redux state
const mapStateToProps = (state: RootState) => ({
  userData: state.userReducer,
});

// Connect the component to Redux
const connector = connect(mapStateToProps);

const TermAndCondition = () => {
  const [settings, setSettings] = useState<Settings>({
    term_and_condition: "",
  });
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = async () => {
    try {
      let formData = new FormData();
      let res = await Api.post(APIURLS.GET_SETTINGS, formData);
      let responseData = res.data;
      setLoading(false);
      if (responseData.status === "success") {
        setSettings(responseData.data);
      }
    } catch (error) {
      setLoading(false); // Ensure that loading is set to false even if an error occurs
      // Handle the error if needed
      console.error(error);
    }
  };

  if (loading) {
    return <PageLoading />;
  }

  return (
    <div>
      <div className="page-wrapper term-page">
        <div className="container">
          <div className="row wow slideInUp">
            <div className="col-12">
              <h1 className="page-heading">Terms and conditions</h1>
            </div>
          </div>
          <div className="row wow slideInUp">
            <div className="col-12">
              <div className="terms-wrapper">
                {parse(settings.term_and_condition)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connector(TermAndCondition);
