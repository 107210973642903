import React, { useContext } from "react";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";

// Define the structure of the state and the dispatch function
interface FilterState {
  payAtHotel: boolean;
}

interface FilterAction {
  type: typeof UPDATE_FILTER;
  payload: {
    payAtHotel: boolean;
  };
}

const PayAtHotelFilter: React.FC = () => {
  const { state, dispatch } = useContext<{
    state: FilterState;
    dispatch: React.Dispatch<FilterAction>;
  }>(FilterContext as any);

  const checkBoxClick = () => {
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        payAtHotel: !state.payAtHotel,
      },
    });
  };

  return (
    <section className="sidebar-box sidebar-Price-section">
      <h6>{"Pay at hotel"}</h6>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{"Pay at hotel"}</p>
        <input
          checked={state.payAtHotel}
          onClick={checkBoxClick}
          type={"checkbox"}
        />
      </div>
    </section>
  );
};

export default PayAtHotelFilter;
