import React, { useEffect, useState, useContext } from "react";
import { Spinner } from "react-bootstrap";
import styles from "./ApplyCoupon.module.css";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { CalculatePrice } from "../../helper/PriceCalculator";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import { UPDATE_ROOM_DATA } from "../../Reducers/HotelView/HotelReducer";

interface ApplyCouponProps {}

const ApplyCoupon: React.FC<ApplyCouponProps> = () => {
  const hotelContext = useContext(HotelContext);
  const [loading, setLoading] = useState<boolean>(false);
  const [couponsData, setCouponsData] = useState<any[]>([]);
  const [showCoupons, setShowCoupons] = useState<boolean>(false);
  const [couponAdded, setCouponAdded] = useState<string>("");
  const [couponApplied, setCouponApplied] = useState<boolean>(false);
  const [applyingCoupon, setApplyingCoupon] = useState<boolean>(false);
  const [listCouponError, setListCouponError] =
    useState<string>("No Coupons Found");
  const [applyCouponError, setApplyCouponError] = useState<string>("");

  const { payablePrice } = CalculatePrice(hotelContext?.hotelState as any);

  useEffect(() => {
    getAvailableCoupons();
  }, []);

  const getAvailableCoupons = async () => {
    setLoading(true);
    try {
      const token = await localStorage.getItem("token");
      const formData = new FormData();
      formData.append("token", token ?? "");
      const res = await Api.post(APIURLS.GETALLCOUPONS, formData);
      const data = res.data;
      if (data.status === "success") {
        setCouponsData(data.data);
      } else {
        if (data.status_code === 1640) {
          setListCouponError("Login to access coupon");
        } else {
          setListCouponError("No Coupons Found");
          setCouponsData([]);
        }
      }
    } catch (err) {
      setListCouponError("No Coupons Found");
      setCouponsData([]);
    } finally {
      setLoading(false);
    }
  };

  const applyCoupon = async () => {
    setApplyCouponError("");
    setApplyingCoupon(true);
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token ?? "");
      formData.append("coupon_code", couponAdded);
      formData.append("total_amount", payablePrice.toString());
      const res = await Api.post(APIURLS.APPLYCOUPON, formData);
      const data = res.data;
      if (data.status === "success") {
        setCouponApplied(true);
        const payload = {
          offerfinalamount: data.finalAmount,
          discountedAmount: data.discountAmount,
        };
        hotelContext?.hotelDispatch({
          type: UPDATE_ROOM_DATA,
          payload: payload,
        });
      } else {
        if (data.status_code === 1640) {
          setApplyCouponError("Please login to apply coupon");
        } else {
          setApplyCouponError(data.msg);
        }
      }
    } catch (err) {
      setApplyCouponError((err as { message: string }).message);
    } finally {
      setApplyingCoupon(false);
    }
  };

  useEffect(() => {
    if (hotelContext?.hotelState.discountedAmount === 0) {
      cancelCoupon();
    }
  }, [hotelContext?.hotelState.discountedAmount]);

  const cancelCoupon = () => {
    setCouponApplied(false);
    const payload = {
      offerfinalamount: 0,
      discountedAmount: 0,
    };
    hotelContext?.hotelDispatch({
      type: UPDATE_ROOM_DATA,
      payload: payload,
    });
    setCouponAdded("");
  };

  return (
    <div className="coupon-wrapper">
      <div>
        <input
          className="form-control"
          type="text"
          name=""
          placeholder="Coupon Code"
          disabled={couponApplied}
          value={couponAdded}
          onChange={(e) => setCouponAdded(e.target.value)}
        />
        {couponApplied ? (
          <input
            className="apply-btn"
            type="button"
            value={"Cancel"}
            onClick={(e) => {
              e.preventDefault();
              cancelCoupon();
            }}
          />
        ) : (
          <input
            className="apply-btn"
            type="button"
            value={applyingCoupon ? "Wait..." : "Apply"}
            onClick={(e) => {
              e.preventDefault();
              if (applyingCoupon) {
                return;
              }
              if (couponApplied) {
                cancelCoupon();
              } else {
                applyCoupon();
              }
            }}
          />
        )}
      </div>
      <div>
        <small className={"text-danger font-weight-bold"}>
          {applyCouponError}
        </small>
      </div>
      {couponApplied ? (
        <div>
          <small className={"text-success font-weight-bold"}>
            {"Coupon applied -- Rs. " +
              hotelContext?.hotelState.discountedAmount +
              " off"}
          </small>
        </div>
      ) : (
        <span
          onClick={() => {
            setShowCoupons(!showCoupons);
          }}
          className={`btn btn-link`}
        >
          <small className={"font-weight-bold"}>{"View All Coupons"}</small>
        </span>
      )}

      <div
        className={`${styles.couponPopUpBlock} shadow ${
          showCoupons ? "d-block" : "d-none"
        }`}
      >
        <div className={styles.topRow}>
          <span>{"Apply Coupon"}</span>
          <span
            onClick={() => setShowCoupons(false)}
            className={styles.closeIcon}
          >
            <i className="fas fa-times"></i>
          </span>
        </div>
        {loading ? (
          <div className={"d-block text-center py-3"}>
            <Spinner animation="border" />
          </div>
        ) : (
          <>
            {couponsData.length === 0 ? (
              <div>
                <p className={"py-4 px-2 text-center"}>{listCouponError}</p>
              </div>
            ) : (
              <div>
                {couponsData.map((coupon, index) => (
                  <div key={index.toString()} className={styles.couponRow}>
                    <div className={styles.coupon_copy_row}>
                      <span className={styles.coupon}>
                        {coupon.coupon_code}
                      </span>
                      <span
                        onClick={() => {
                          setCouponAdded(coupon.coupon_code);
                          setShowCoupons(false);
                        }}
                        className={styles.coupon_copy}
                      >
                        COPY
                      </span>
                    </div>
                    <p className={"my-1 mx-1"}>
                      <small className={"font-weight-bold"}>
                        {coupon.title}
                      </small>
                    </p>
                    <hr className={"m-0"} />
                    <div>
                      <p>
                        <small className={"text-muted"}>
                          {`( Min. Booking Amount : ₹ ${coupon.minimum_spend} )`}
                        </small>
                        <span className={"d-block"}>{coupon.sub_title}</span>
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ApplyCoupon;
