import React, { useState, useContext, useEffect } from "react";
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { CalculatePrice } from "../../helper/PriceCalculator";
import { UPDATE_HOTEL_ROOM_SELECTION } from "../../Reducers/HotelView/HotelReducer";
import AmenitiesWithFilter from "./AmenitiesWithFilter";
import { CONSTANTS } from "../../utils/Constants";

interface Room {
  id: number;
  actual_price: number;
  cut_off_price: number;
  room_category: string;
  room_images: { image: string }[];
  amenities: string[];
}

interface HotelData {
  master_image: string;
  hotel_name: string;
  vendor_id: string;
  id: any;
}

interface SelectRoomCategorySectionProps {
  rooms: Room[];
  hoteldata: HotelData;
  selected_room?: any;
}

const SelectRoomCategorySection: React.FC<SelectRoomCategorySectionProps> = (
  props
) => {
  const [selectedRoom, setSelectedRoom] = useState<number>(1);
  const hotelContext = useContext(HotelContext);
  const { payablePrice, offPrice } = CalculatePrice(
    hotelContext?.hotelState as any
  );

  const onSelectRoom = (roomID: number) => {
    setSelectedRoom(roomID);
    const findRoom = props.rooms.find(
      (room) => Number(room.id) === Number(roomID)
    );

    if (findRoom) {
      const payload = {
        hotel_image: props.hoteldata.master_image,
        hotel_name: props.hoteldata.hotel_name,
        vendor_id: props.hoteldata.vendor_id,
        hotel_id: props.hoteldata.id,
        room_id: findRoom.id,
        min_price: findRoom.actual_price,
        max_price: findRoom.cut_off_price,
        room_category: findRoom.room_category,
      };
      hotelContext?.hotelDispatch({
        type: UPDATE_HOTEL_ROOM_SELECTION,
        payload: payload as any,
      });
    } else {
      onSelectRoom(props.rooms[0].id);
    }
  };

  useEffect(() => {
    if (props.rooms.length > 0) {
      if (props.selected_room === undefined || props.selected_room === 0) {
        onSelectRoom(props.rooms[0].id);
      } else {
        onSelectRoom(props.selected_room);
      }
    }
  }, [props.rooms, props.selected_room]);

  return (
    <section>
      <h2 className="details-sub-heading my-3">Room Selected</h2>
      {props.rooms.map((room, index) => {
        const room_images = room.room_images;
        return (
          <div
            style={{ cursor: "pointer" }}
            onClick={() => onSelectRoom(room.id)}
            key={index.toString()}
            className="Room-Selected"
          >
            {selectedRoom === room.id && (
              <h1>
                <i className="fas fa-star"></i> SELECTED CATEGORY
              </h1>
            )}

            <div className="selected-room-contant">
              <div className="selected-room-details">
                <h2>{room.room_category}</h2>
                {/* all Amenities */}
                <AmenitiesWithFilter roomAmenity={room.amenities} />
              </div>
              <div className="selected-room-img">
                {room_images.length > 0 ? (
                  <img
                    className="img-fluid"
                    src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${room.room_images[0].image}`}
                  />
                ) : props.hoteldata.master_image ? (
                  <img
                    className="img-fluid"
                    src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${props.hoteldata.master_image}`}
                  />
                ) : (
                  <img
                    className="img-fluid"
                    src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${room.room_images[0].image}`}
                  />
                )}
              </div>
            </div>
            <div className="hotelvilahouse-price">
              <span className="main-price">
                <i className="fas fa-rupee-sign"></i>{" "}
                {selectedRoom === room.id ? payablePrice : room.actual_price}
              </span>
              <span className="off-price">
                <i className="fas fa-rupee-sign"></i>{" "}
                {selectedRoom === room.id ? offPrice : room.cut_off_price}
              </span>

              {selectedRoom === room.id ? (
                <span className="discount-price">
                  {(((offPrice - payablePrice) * 100) / offPrice).toFixed() +
                    "% off"}
                </span>
              ) : (
                <span className="discount-price">
                  {(
                    ((room.cut_off_price - room.actual_price) * 100) /
                    room.cut_off_price
                  ).toFixed() + "% off"}
                </span>
              )}
            </div>
          </div>
        );
      })}
    </section>
  );
};

export default SelectRoomCategorySection;
