import { Person, Star } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import React, { memo, useState } from "react";
import Carousel from "react-multi-carousel";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { CONSTANTS } from "../../utils/Constants";

const useStyles = makeStyles({
  singleReviewRow: {
    borderBottom: "1px solid #e7e7e7",
    padding: "15px 0px !important",
  },
  userDetailRow: {
    display: "flex",
    justifyContent: "space-between",
  },
  userNameText: {
    fontSize: 13,
    margin: "0px 5px !important",
    color: "2d2d2d",
    fontWeight: 600,
    marginLeft: "10px !important",
    textTransform: "capitalize",
  },
  spaceDot: {
    width: 5,
    height: 5,
    background: "#2d2d2d",
    borderRadius: 50,
    margin: "0px 5px !important",
  },
  reviewDate: {
    fontSize: "13px !important",
    color: "2d2d2d",
  },
  reviewChip: {
    background: "#004588",
    color: "#fff",
    fontSize: 13,
    display: "flex",
    alignItems: "center",
    padding: "2px 8px !important",
    fontWeight: 700,
    borderRadius: 5,
    height: 25,
  },
  reviewText: {
    color: "#2d2d2d",
    fontWeight: 500,
    margin: "10px 0px !important",
    fontSize: 14,
  },
});

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

interface Review {
  username: string;
  created_at: string;
  rating: number;
  review: string;
  images: string;
}

interface SingleRatingRowProps {
  review: Review;
}

const SingleRatingRow: React.FC<SingleRatingRowProps> = (props) => {
  const { review } = props;
  const images: string[] =
    review.images !== "" ? JSON.parse(review.images) : [];
  const classes = useStyles();
  const [open, setOpen] = useState<{ status: boolean; index: number }>({
    status: false,
    index: 0,
  });

  return (
    <Box className={classes.singleReviewRow}>
      <Box className={classes.userDetailRow}>
        <Box display={"flex"} alignItems="center">
          <Avatar sx={{ width: 35, height: 35, background: "#bdc4da" }}>
            <Person />
          </Avatar>
          <h4 className={classes.userNameText}>{review.username}</h4>
          <Box className={classes.spaceDot} />
          <p className={classes.reviewDate}>
            {moment(review.created_at).format("DD MMM YYYY")}
          </p>
        </Box>
        <Box className={classes.reviewChip}>
          {Number(review.rating).toFixed(1)}
          <Star style={{ fontSize: 12, marginLeft: 2 }} />
        </Box>
      </Box>
      <p className={classes.reviewText}>{review.review}</p>
      {images.length > 0 && (
        <Box display={"flex"} alignItems="center" flexWrap={"wrap"}>
          {images.map((image, index) => (
            <img
              onClick={() => setOpen({ status: true, index: index })}
              style={{
                width: 60,
                height: 60,
                borderRadius: 8,
                margin: 10,
              }}
              key={index}
              src={`${CONSTANTS.IMAGE_BASE_URL}/reviews/${image}`}
              alt="hotel-review-images"
            />
          ))}
        </Box>
      )}

      <Dialog
        open={open.status}
        onClose={() => setOpen({ status: false, index: 0 })}
      >
        <DialogTitle
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {"Images"}

          <IconButton
            sx={{ marginLeft: "auto" }}
            edge="start"
            color="inherit"
            aria-label="close"
            onClick={() => setOpen({ status: false, index: 0 })}
          >
            <FontAwesomeIcon width={24} height={24} icon={faTimes} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Carousel
            showDots
            ssr
            slidesToSlide={1}
            responsive={responsive}
            infinite
            swipeable={true}
            draggable={false}
            partialVisible
          >
            {images.map((img, index) => {
              return (
                <div
                  key={`_${index}`}
                  style={{
                    height: 350,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{
                      maxHeight: "100%",
                      maxWidth: "100%",
                      alignSelf: "center",
                    }}
                    src={`${CONSTANTS.IMAGE_BASE_URL}/reviews/${img}`}
                    alt="hotel-review-images"
                  />
                </div>
              );
            })}
          </Carousel>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default memo(SingleRatingRow);
