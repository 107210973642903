import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Api from "../../../services/api.service";

interface City {
  city_id: string;
  city_name: string;
}

const FooterCitiesSection: React.FC = () => {
  const [CitiesList, setCitiesList] = useState<City[]>([]);
  const [DataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    getCities();
  }, []);

  const getCities = () => {
    setDataLoading(true);
    const formData = new FormData();
    Api.post("home/get_all_cities", formData).then((res) => {
      const data = res.data["data"] as City[];

      if (res.data["status"] === "success") {
        setCitiesList(data);
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    });
  };

  return (
    <div className="row footer-section3 wow slideInUp">
      <div className="col-sm-12">
        <h1>Silver Stays</h1>
      </div>

      {CitiesList.map((list) => (
        <div key={list.city_id} className="col-lg-3">
          <Link
            to={`/hotel-by-city/${list.city_id}`}
            className="footer-city-link"
          >
            <i className="fas fa-angle-double-right"></i>
            {list.city_name}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default FooterCitiesSection;
