import React from "react";
import AmenitiesFilter from "./AmenitiesFilter";
import GuestRatingFilter from "./GuestRatingFilter";
import PayAtHotelFilter from "./PayAtHotelFilter";
import PriceFilter from "./PriceFilter";
import RoomCategoryFilter from "./RoomCategoryFilter";
import SortBy from "./SortBy";

const FilterSection: React.FC = () => {
  return (
    <>
      <div className="short-filter-wrapper-inner-mobile">
        <i id="filter-btn" className="fas fa-filter"></i>
        <select className="form-control">
          <option>Popularity</option>
          <option>Guest Ratings</option>
          <option>Price Low To High</option>
          <option>Price High To Low</option>
        </select>
      </div>
      <div className="left-filter-sidebar">
        <section className="sidebar-box">
          <h1>Filters</h1>
        </section>
        <SortBy />
        {/* <LocationFilter /> */}
        <PriceFilter />
        <GuestRatingFilter />
        <PayAtHotelFilter />
        {/* <StartRatings /> */}
        <AmenitiesFilter />
        <RoomCategoryFilter />
      </div>
    </>
  );
};

export default FilterSection;
