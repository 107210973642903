import React from "react";
import ThanksModal from "../../container/modals/ThanksModal";

const ModifyBooking = () => {
  return (
    <>
      <div className="page-wrapper modify-booking-page">
        <section className="wow slideInUp">
          <div className="container">
            <div className="modify-booking-wrapper wow slideInUp">
              <div className="bookingall-details-wrapper">
                <div className="row">
                  <div className="col-md-4 pr-0">
                    <img
                      alt=""
                      className="img-fluid w-100"
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/search-slide-icon2.webp"
                      }
                    />
                  </div>
                  <div className="col-md-6">
                    <h1 className="hotelvilahouse-name">
                      SilverKey Executive Stays 60501 Babul Nagar
                    </h1>
                    <div className="room-rating">
                      <span>
                        4.9 <i className="fas fa-star"></i>
                      </span>{" "}
                      (283 Ratings) · Excellent
                    </div>
                    <div className="modify-date">
                      <span>Thu, 10 Sep Fri, 11 Sep</span>{" "}
                      <span>1 Room, 1 Guest</span>
                    </div>
                    <div className="modify-room-type">
                      <strong>Selected : </strong>classNameic (2X)
                    </div>
                    <div className="modify-price-details">
                      <ul>
                        <li>
                          <div>Room price for 31 Nights X 1 Guest</div>
                          <strong>
                            <i className="fas fa-rupee-sign"></i> 1280
                          </strong>
                        </li>
                        <li>
                          <div>Price Drop</div>
                          <strong>
                            <i className="fas fa-rupee-sign"></i> 250
                          </strong>
                        </li>
                        <li>
                          <div>
                            Payable Amount<span>(incl. of all taxes)</span>
                          </div>
                          <strong>
                            <i className="fas fa-rupee-sign"></i> 1030
                          </strong>
                        </li>
                      </ul>
                    </div>
                    <div className="modify-login-policiy">
                      <a href="/#">Cancellation Policy</a>
                      <a href="/#">Guest Policies</a>
                    </div>
                  </div>
                  <div className="col-md-2">
                    <a href="/#" className="Modify-booking-btn">
                      Modify Booking
                    </a>
                  </div>
                </div>
              </div>
              <div className="bookingall-step1-wrapper wow slideInUp">
                <h1>Step 1</h1>
                <form className="passcode-form">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Full Name</label>
                        <input className="form-control" type="text" name="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Email Id</label>
                        <input className="form-control" type="text" name="" />
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="form-group">
                        <label>Mobile No.</label>
                        <input className="form-control" type="text" name="" />
                      </div>
                    </div>
                    <div className="col-12">
                      <input
                        className="passcode-btn"
                        type="button"
                        value="Send Passcode"
                        name=""
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="bookingall-step2-wrapper wow slideInUp">
                <h1>Step 2</h1>
                <div className="row py-md-5 py-2">
                  <div className="col-md-4">
                    <ul className="nav nav-pills" role="tablist">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="pill"
                          href="#pay-hotel"
                        >
                          Pay At Hotel
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="pill"
                          href="#pay-now"
                        >
                          Pay Now
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-md-8">
                    <div className="tab-content">
                      <div id="pay-hotel" className="pay-hotel tab-pane active">
                        <h3>No payment needed today</h3>
                        <p>
                          We will confirm your stay without any charge.
                          <br />
                          Pay directly at the hotel during your stay.
                        </p>

                        <input
                          className="booking-confirm"
                          type="button"
                          value="Confirm Booking"
                          name=""
                          data-toggle="modal"
                          data-target="#thankyou"
                        />
                      </div>
                      <div
                        id="pay-now"
                        className="payment text-center tab-pane fade"
                      >
                        <h3>AD Card and Pay</h3>
                        <p>
                          Ut enim ad minim veniam, quis nostrud exercitation
                          ullamco laboris nisi ut aliquip ex ea commodo
                          consequat.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <ThanksModal /> */}
    </>
  );
};

export default ModifyBooking;
