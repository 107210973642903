import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { Box, IconButton, TextField } from "@mui/material";
import { Close } from "@mui/icons-material";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import { CONSTANTS } from "../../utils/Constants";
import { Rating } from "react-simple-star-rating";
import RatingImagesUploader from "../../container/modals/RatingImagesUploader";

// Define RoomImage interface
interface RoomImage {
  room_image: string | null;
}

interface RoomData {
  hotel_id: number;
  booking_id: number;
  room_id: number;
  vendor_id: number;
  hotel_name: string;
  user_rating: string | null;
  room_details: {
    room_images: string;
  };
}

interface GiveRatingProps {
  roomdata: RoomData;
}

const GiveRating: React.FC<GiveRatingProps> = ({ roomdata }) => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rating, setRating] = useState<number>(1);
  const [roomImages, setRoomImages] = useState<RoomImage[]>([]);
  const [images, setImages] = useState<RoomImage[]>([]);
  const [review, setReview] = useState<string>("");

  useEffect(() => {
    if (roomdata.user_rating !== null) {
      setRating(Number(roomdata.user_rating));
    }
    setRoomImages(JSON.parse(roomdata.room_details.room_images));
  }, [roomdata]);

  const giveRating = async () => {
    setLoading(true);
    if (rating === 0) {
      alert("Give at least 1 rating"); // Consider using a different approach for alerts
      setLoading(false);
      return;
    }

    const formData = new FormData();
    const token = await localStorage.getItem("token");
    if (token) {
      formData.append("token", token);
    }
    formData.append("hotel_id", roomdata.hotel_id.toString());
    formData.append("booking_id", roomdata.booking_id.toString());
    formData.append("room_id", roomdata.room_id.toString());
    formData.append("vendor_id", roomdata.vendor_id.toString());
    formData.append("rating", rating.toString());
    formData.append("review", review);
    formData.append("images", JSON.stringify(images));

    try {
      const res = await Api.post(APIURLS.GIVERATING, formData);
      setLoading(false);
      const data = res.data;
      if (data.status === "success") {
        alert(`${data.title}: ${data.msg}`); // Consider using a different approach for alerts
        setRating(1);
        setImages([]);
        setReview("");
        setShow(false);
      } else {
        alert(`${data.title}: ${data.msg}`); // Consider using a different approach for alerts
      }
    } catch (err) {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Give Rating</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <div className="text-center">
              {roomImages.length > 0 && roomImages[0].room_image ? (
                <img
                  alt="room-images-alt"
                  className="img-fluid"
                  src={`${CONSTANTS.IMAGE_BASE_URL}/rooms/${roomImages[0].room_image}`}
                  style={{ width: "70%", height: 180, margin: "auto" }}
                />
              ) : (
                <img
                  alt="default-room-image"
                  className="img-fluid"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/default_property.png"
                  }
                  style={{ width: "70%", height: 180, margin: "auto" }}
                />
              )}
            </div>

            <div className="my-3">
              <h5
                className="text-center pt-3 font-weight-bold"
                style={{ color: CONSTANTS.PRIMARY_COLOR }}
              >
                {roomdata.hotel_name}
              </h5>

              <p
                style={{
                  fontSize: 16,
                  letterSpacing: 0.8,
                  color: "#a7acc7",
                  textAlign: "center",
                  marginTop: 15,
                }}
              >
                Rate Our Services
              </p>

              <div className="text-center">
                <Rating
                  iconsCount={5}
                  className="mx-auto no-focus"
                  onClick={(newRating) => setRating(newRating)}
                  size={34}
                  initialValue={rating}
                  fillColor={CONSTANTS.PRIMARY_COLOR}
                />
              </div>

              <Box px={5}>
                <RatingImagesUploader images={images} setImages={setImages} />

                <Box my={2}>
                  <TextField
                    onChange={(e) => setReview(e.target.value)}
                    fullWidth
                    id="outlined-multiline-static"
                    label="Write Your Review"
                    multiline
                    rows={5}
                  />
                </Box>
              </Box>

              <div className="text-center py-1">
                <button
                  disabled={loading}
                  className="btn"
                  onClick={giveRating}
                  style={{ backgroundColor: "#1E5698", color: "#fff" }}
                >
                  {loading ? "Loading..." : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <button
        onClick={() => setShow(true)}
        type="button"
        className="btn btn-sm mx-2"
        style={{ backgroundColor: "#1E5698", color: "#fff" }}
      >
        Give Rating
      </button>
    </>
  );
};

export default GiveRating;
