import Api from "../../services/api.service";
import {
  AUTHENTICATE,
  LOGIN,
  LOGOUT,
  UPDATE_USER,
} from "../actionTypes/userActionsType";

interface UserPayload {
  userType: string;
  [key: string]: any;
}

interface LoginAction {
  type: typeof LOGIN;
  payload: UserPayload;
}

interface LogoutAction {
  type: typeof LOGOUT;
}

interface AuthenticateAction {
  type: typeof AUTHENTICATE;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  payload: UserPayload;
}

export const userLogin = (payload: UserPayload): LoginAction => ({
  type: LOGIN,
  payload: payload,
});

export const userLogout = (): LogoutAction => ({
  type: LOGOUT,
});

export const userAuthenticate = (): AuthenticateAction => ({
  type: AUTHENTICATE,
});

export const updateUser = (payload: UserPayload): UpdateUserAction => ({
  type: UPDATE_USER,
  payload: payload,
});

export const userThunkLogin = (token: string, userType: string) => {
  return async function (dispatch: any) {
    try {
      dispatch(userAuthenticate());
      await localStorage.setItem("token", token);
      await localStorage.setItem("userType", userType);
      let formData = new FormData();
      formData.append("token", token);
      let res;
      if (userType === "vendor") {
        res = await Api.post("/Vendor/vendor/get_profile", formData);
      } else {
        res = await Api.post("/user/get_profile", formData);
      }
      let data = res.data;
      if (data.status !== "success") {
        dispatch(userLogout());
        return;
      }
      let payload: UserPayload = {
        userType: userType,
        ...data.data,
      };
      dispatch(userLogin(payload));
    } catch (error) {
      dispatch(userLogout());
    }
  };
};

export const checkAuthenticate = () => {
  return async function (dispatch: any) {
    try {
      let token = await localStorage.getItem("token");
      if (token === null || token === undefined || token === "") {
        dispatch(userLogout());
      } else {
        let userType = await localStorage.getItem("userType");
        let formData = new FormData();
        formData.append("token", token);
        let res;
        if (userType === "vendor") {
          res = await Api.post("/Vendor/vendor/get_profile", formData);
        } else {
          res = await Api.post("/user/get_profile", formData);
        }

        let data = res.data;

        if (data.status !== "success") {
          dispatch(userLogout());
          return;
        }
        // Check User Mobile is active and verified
        let payload: UserPayload = {
          userType: userType,
          ...data["data"],
          settings: data["settings"],
        };
        dispatch(userLogin(payload));
      }
    } catch (e) {
      dispatch(userLogout());
    }
  };
};

export const updateUserThunk = () => {
  return async function (dispatch: any) {
    try {
      let token = await localStorage.getItem("token");
      if (token === null || token === undefined || token === "") {
        dispatch(userLogout());
      } else {
        let userType = await localStorage.getItem("userType");
        let formData = new FormData();
        formData.append("token", token);
        let res = await Api.post("/user/get_profile", formData);
        let data = res.data;
        if (data.status !== "success") {
          dispatch(userLogout());
          return;
        }
        let payload: UserPayload = {
          userType: userType,
          ...data["data"],
          settings: data["settings"],
        };
        dispatch(updateUser(payload));
      }
    } catch (e) {
      dispatch(userLogout());
    }
  };
};

export const logout = () => {
  return function (dispatch: any) {
    localStorage.removeItem("token");
    dispatch(userLogout());
  };
};
