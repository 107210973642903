import React from "react";
import { UPDATE_FILTER } from "../../Reducers/FilterView/FilterReducer";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";

// Define the types for the context state and dispatch
interface FilterState {
  guestRating: boolean;
}

interface FilterAction {
  type: typeof UPDATE_FILTER;
  payload: {
    guestRating: boolean;
  };
}

const GuestRatingFilter: React.FC = () => {
  const { state, dispatch } = React.useContext(FilterContext) as {
    state: FilterState;
    dispatch: React.Dispatch<FilterAction>;
  };

  const checkBoxClick = () => {
    dispatch({
      type: UPDATE_FILTER,
      payload: {
        guestRating: !state.guestRating,
      },
    });
  };

  return (
    <section className="sidebar-box sidebar-Price-section">
      <h6>Guest Rating</h6>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <p>{"Highest Guest Rating"}</p>
        <input
          checked={state.guestRating}
          onClick={checkBoxClick}
          type={"checkbox"}
        />
      </div>
    </section>
  );
};

export default GuestRatingFilter;
