import { Close, Star } from "@mui/icons-material";
import {
  Box,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import SingleRatingRow from "./SingleRatingRow";
import ApiRequest from "../../services/ApiRequest.service";
import { APIURLS } from "../../services/APIURLS";
import RatingReviewSkeleton from "../hotel-view/RatingReviewSkeleton";

const useStyles = makeStyles({
  overallRatingSection: {
    margin: "20px 0px",
    borderRadius: 5,
  },
  ratingLeftBlock: {
    textAlign: "center",
    padding: 20,
  },
  averageRatingChip: {
    background: "#004588",
    color: "white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: 85,
    padding: 5,
    borderRadius: 5,
    fontSize: 20,
    fontWeight: "bold",
    margin: "auto",
  },
  ratingFeedback: {
    fontSize: "18px !important",
    margin: "5px 0px !important",
    fontWeight: 600,
    color: "#8888",
    textTransform: "capitalize",
  },
  totalRatingText: {
    fontSize: "16px !important",
    margin: "5px 0px !important",
    color: "#8888",
    fontWeight: 500,
  },

  viewReviewButton: {
    color: "#004588 !important",
    fontWeight: "700 !important",
    fontSize: "15px !important",
    padding: "10px 0 !important",
  },
  barColorPrimary: {
    backgroundColor: "#f3a413",
  },
  drawerPaper: {
    width: 500,
    padding: 20,
  },
});

interface Props {
  open: boolean;
  toggleDrawer: () => void;
}

interface Review {
  id: any;
  username: string;
  created_at: string;
  rating: number;
  review: string;
  images: string;
}

interface OverallRatings {
  AvgRating: number;
  totalRating: number;
}

const ViewRatingsDrawer: React.FC<Props> = (props) => {
  const classes = useStyles();
  const { open, toggleDrawer } = props;
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [reviews, setReviews] = useState<Review[]>([]);
  const [overallRatings, setOverallRatings] = useState<OverallRatings>({
    AvgRating: 0,
    totalRating: 0,
  });

  const getReviews = useCallback(() => {
    const token = localStorage.getItem("token");
    const formData = new FormData();
    formData.append("hotel_id", id ?? "");
    formData.append("token", token!);

    ApiRequest({
      loading: setLoading,
      url: APIURLS.GETHOTELREVIEWSRATING,
      values: formData,
      onSuccessCallback: async (res) => {
        setReviews(res.data);
        const averageRating = Number(res.average_rating);
        const totalRating = Number(res.totalRatingsReview);

        setOverallRatings({
          AvgRating: averageRating,
          totalRating: totalRating,
        });
      },
      onError: () => {
        setReviews([]);
      },
    });
  }, [id]);

  const overallFeedback = useMemo(() => {
    if (overallRatings.AvgRating <= 1) {
      return "BAD";
    } else if (overallRatings.AvgRating <= 2) {
      return "OK";
    } else if (overallRatings.AvgRating <= 3) {
      return "JUST GOOD";
    } else if (overallRatings.AvgRating <= 4) {
      return "GOOD";
    } else if (overallRatings.AvgRating <= 5) {
      return "VERY GOOD";
    }
  }, [overallRatings.AvgRating]);

  useEffect(() => {
    getReviews();
  }, [getReviews]);

  return (
    <Drawer
      PaperProps={{
        className: classes.drawerPaper,
      }}
      anchor="right"
      open={open}
      onClose={toggleDrawer}
    >
      <Box display={"flex"} alignItems="center">
        <IconButton onClick={toggleDrawer}>
          <Close sx={{ color: "#333" }} />
        </IconButton>
        <Typography
          sx={{
            fontWeight: "600",
            color: "#333",
            marginLeft: "10px !important",
          }}
        >
          All {overallRatings.totalRating} Reviews
        </Typography>
      </Box>

      {loading && <RatingReviewSkeleton />}

      {!loading && (
        <>
          <Box className={classes.overallRatingSection}>
            <Grid container alignItems={"center"}>
              <Grid item lg={12}>
                <Box className={classes.ratingLeftBlock}>
                  <Box className={classes.averageRatingChip}>
                    {overallRatings.AvgRating.toFixed(1)}{" "}
                    <Star style={{ fontSize: 16, marginLeft: 3 }} />
                  </Box>
                  <p className={classes.ratingFeedback}>{overallFeedback}</p>
                  <p className={classes.totalRatingText}>
                    {overallRatings.totalRating} Ratings
                  </p>
                </Box>
              </Grid>
            </Grid>
          </Box>
          {reviews.length > 0 &&
            reviews.map((review, index) => (
              <SingleRatingRow review={review} key={index} />
            ))}
        </>
      )}
    </Drawer>
  );
};

export default memo(ViewRatingsDrawer);
