import React, { useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import OtpInput from "react-otp-input";
import Api from "../../services/api.service";

interface ResetOtpVerifyProps {
  show: boolean;
  ToggleVerifyModal: () => void;
  ToggleResetModal: () => void;
}

const ResetOtpVerify: React.FC<ResetOtpVerifyProps> = (props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [resetOtp, setResetOtp] = useState<string>("");
  const [formErrors, setFormErrors] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");

  const handleSubmit = () => {
    setLoading(true);
    if (resetOtp === "") {
      setFormErrors("Please Enter OTP");
      setLoading(false);
      return;
    }

    const mobile = localStorage.getItem("usermobile");

    if (!mobile) {
      setLoading(false);
      return;
    }

    const formData = new FormData();
    formData.append("mobile", mobile);
    formData.append("code", resetOtp);

    setLoading(true);
    Api.post("/otp/verify_user_web", formData)
      .then((res) => {
        setLoading(false);
        const data = res.data;
        if (data.status === "success") {
          props.ToggleVerifyModal();
          props.ToggleResetModal();
        } else {
          setFormErrors(res.data["msg"]);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  return (
    <>
      <Modal
        animation={true}
        centered
        className="modal-wrapper"
        dialogClassName="w-35"
        show={props.show}
        onHide={props.ToggleVerifyModal}
      >
        <div>
          <button
            type="button"
            className="close"
            onClick={props.ToggleVerifyModal}
          >
            &times;
          </button>
          <h1 className="modaltitle">Reset Verify OTP</h1>
        </div>

        {formSuccess !== "" && <Alert variant="success">{formSuccess}</Alert>}
        {formErrors !== "" && <Alert variant="danger">{formErrors}</Alert>}
        <div>
          <OtpInput
            isInputNum
            containerStyle="otp-input-container"
            inputStyle="otp-input"
            value={resetOtp}
            onChange={(otp: any) => {
              setResetOtp(otp);
            }}
            numInputs={4}
          />
        </div>
        <div className="form-group submit-btn">
          <input onClick={handleSubmit} type="button" value="SUBMIT" name="" />
        </div>
      </Modal>
    </>
  );
};

export default ResetOtpVerify;
