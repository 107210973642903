// HotelReducer.tsx

import moment from "moment";

export const UPDATE_HOTEL_ROOM_SELECTION = "UPDATE_HOTEL_ROOM_SELECTION";
export const UPDATE_ROOM_DATA = "UPDATE_ROOM_DATA";
export const GET_ROOM_DIMENSTION = "GET_ROOM_DIMENSTION";

export interface HotelState {
  hotel_image: string;
  hotel_name: string;
  hotel_id: number;
  room_id?: number; // Make room_id optional if it's not always present
  min_price: number;
  max_price: number;
  checkInDate: string;
  checkOutDate: string;
  rooms: number;
  guest: number;
  discriptionDime: number;
  amenitiesDime: number;
  travelDetailDime: number;
  ratingreviewDime: number;
  whatsNearbyDime: number;
  offerfinalamount: number;
  discountedAmount: number;
  room_category: string;
  roomChargeSettings: Record<string, any>;
  nightChargeSettings: Record<string, any>;
  guestChargeSettings: Record<string, any>;
  vendor_id?: number; // Define vendor_id as optional if it may not always be present
}

export interface HotelAction {
  type: string;
  payload: Partial<HotelState>;
}

export const initialStateHotel: HotelState = {
  hotel_image: "",
  hotel_name: "",
  hotel_id: 0,
  room_id: 0,
  min_price: 0,
  max_price: 0,
  checkInDate: moment().utcOffset("+05:30").format("YYYY-MM-DD"),
  checkOutDate: moment()
    .add(1, "days")
    .utcOffset("+05:30")
    .format("YYYY-MM-DD"),
  rooms: 1,
  guest: 1,
  discriptionDime: 0,
  amenitiesDime: 0,
  travelDetailDime: 0,
  ratingreviewDime: 0,
  whatsNearbyDime: 0,
  offerfinalamount: 0,
  discountedAmount: 0,
  room_category: "Economy",
  roomChargeSettings: {},
  nightChargeSettings: {},
  guestChargeSettings: {},
};

export const HOTEL_REDUCER = (
  state: HotelState = initialStateHotel,
  action: HotelAction
): HotelState => {
  switch (action.type) {
    case UPDATE_HOTEL_ROOM_SELECTION:
      return {
        ...state,
        hotel_image: action.payload.hotel_image!,
        hotel_name: action.payload.hotel_name!,
        vendor_id: action.payload.vendor_id!, // Make sure vendor_id is defined in payload
        hotel_id: action.payload.hotel_id!,
        room_id: action.payload.room_id!,
        min_price: action.payload.min_price!,
        max_price: action.payload.max_price!,
        room_category: action.payload.room_category!,
      };
    case UPDATE_ROOM_DATA:
      return {
        ...state,
        ...action.payload,
      };
    case GET_ROOM_DIMENSTION:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
