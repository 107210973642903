import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "../online-payment-page/Loader.css";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";

interface ResData {
  status: string;
  title: string;
  message: string;
}

const PaymentStatusPage: React.FC = () => {
  const { booking_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [resData, setResData] = useState<ResData>({
    status: "error",
    title: "",
    message: "",
  });

  const checkStatus = useCallback(async () => {
    let token = localStorage.getItem("token") || "";
    let formData = new FormData();
    formData.append("token", token);
    formData.append("booking_id", booking_id ?? "");
    setLoading(true);

    try {
      const res = await Api.post(APIURLS.PAYMENT_STATUS, formData);
      let data = res.data;

      if (data.status === "success") {
        if (data.data.status === "Success") {
          setResData({
            status: "success",
            title: "Success",
            message: "Booking Confirm",
          });
        } else if (data.data.status === "Aborted") {
          setResData({
            status: "error",
            title: "Aborted",
            message: "You Cancelled Booking",
          });
        } else {
          setResData({
            status: "error",
            title: "Failure",
            message: "Your Booking is not completed",
          });
        }
      } else {
        setResData({
          status: "error",
          title: "OOPS!",
          message: data.msg,
        });
      }
    } catch (err) {
      setResData({
        status: "error",
        title: "OOPS!",
        message: (err as { message: string }).message,
      });
    } finally {
      setLoading(false);
    }
  }, [booking_id]);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  if (loading) {
    return (
      <div className="center_loading_container">
        <div className="loader"></div>
        <h4>Please wait...</h4>
      </div>
    );
  } else if (resData.status === "success") {
    return (
      <div className="text-center" style={{ padding: "200px 20px" }}>
        <div>
          <div
            style={{ width: "500px", maxWidth: "100%" }}
            className="alert alert-success m-auto"
            role="alert"
          >
            <h2 className="alert-heading">{resData.status.toUpperCase()}</h2>
            <hr />
            <p className="mb-0">{resData.message}</p>
            <Link className="theme-btn" to="/profile/booking">
              View Booking
            </Link>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="text-center" style={{ padding: "200px 20px" }}>
        <div>
          <div
            style={{ width: "500px", maxWidth: "100%" }}
            className="alert alert-danger m-auto"
            role="alert"
          >
            <h2 className="alert-heading">{resData.status.toUpperCase()}</h2>
            <hr />
            <p className="mb-0">{resData.message}</p>
          </div>
        </div>
      </div>
    );
  }
};

export default PaymentStatusPage;
