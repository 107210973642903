import React from "react";
import { connect, ConnectedProps } from "react-redux";
// import { RootState } from "./store"; // Adjust the path according to your project structure

// Define the shape of user data
interface UserData {
  full_name: string;
  email: string;
  mobile: string;
  address: string;
  nickname: string;
  dob: string;
}

// Define the shape of props received by the component
type PropsFromRedux = ConnectedProps<typeof connector>;
interface UserDashboardProps extends PropsFromRedux {}

// The UserDashboard functional component
const UserDashboard: React.FC<UserDashboardProps> = ({ userData }) => (
  <div id="home" className={`dashboard tab-pane`}>
    <h2 className="tabs-heading">Profile Information</h2>
    <ul className="dashboard-info-list">
      <li>
        <i className="fas fa-user-circle"></i>
        <strong>Name :</strong> {userData.full_name}
      </li>
      <li>
        <i className="fas fa-envelope"></i>
        <strong>Email :</strong> {userData.email}
      </li>
      <li>
        <i className="fas fa-phone-square-alt"></i>
        <strong>Mobile No. :</strong> +91 {userData.mobile}
      </li>
      <li>
        <i className="fas fa-map-marker-alt"></i>
        <strong>Address :</strong> {userData.address}
      </li>
      <li>
        <i className="fas fa-user-circle"></i>
        <strong>Nickname :</strong> {userData.nickname}
      </li>
      <li>
        <i className="far fa-calendar-alt"></i>
        <strong>Birthday :</strong> {userData.dob}
      </li>
    </ul>
  </div>
);

// Map the state to props
const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps);

// Connect the component to the Redux store
export default connector(UserDashboard);
