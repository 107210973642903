import { Add, CloudUpload } from "@mui/icons-material";
import { Box, Button, InputLabel, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { ChangeEvent } from "react";

const useStyles = makeStyles({
  uploadBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    border: "1px solid rgba(0,0,0,.2)",
    cursor: "pointer",
    padding: 50,
    borderRadius: 5,
  },

  viewUploadFiles: {
    border: "1px solid rgba(0,0,0,.2)",
    padding: 15,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  addBtn: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 12,
    width: 60,
    height: 60,
    border: "1px solid rgba(0,0,0,.2)",
    borderRadius: 5,
    margin: 0,
  },
});

const toBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

interface RatingImagesUploaderProps {
  images: any[];
  setImages: React.Dispatch<React.SetStateAction<any[]>>;
}

const RatingImagesUploader: React.FC<RatingImagesUploaderProps> = (props) => {
  const { images, setImages } = props;
  const classes = useStyles();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (!files) return;
    try {
      if (files.length > 0) {
        const formattedFiles: any[] = await Promise.all(
          Array.from(files).map(async (file) => {
            let base64Converted = await toBase64(file);
            base64Converted = base64Converted.replace(
              /^data:image\/[a-z]+;base64,/,
              ""
            );

            return {
              data: base64Converted,
              fileName: file.name,
              type: file.type,
            };
          })
        );
        setImages([...images, ...formattedFiles]);
      } else {
        setImages([]);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      {images.length === 0 && (
        <Box mt={2}>
          <Box
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <InputLabel>Upload Images</InputLabel>
            <Button
              onClick={() => setImages([])}
              sx={{ padding: 0, marginBottom: 1, textTransform: "capitalize" }}
              color="error"
            >
              Clear Files
            </Button>
          </Box>
          <label htmlFor="review-file-uploader" className={classes.uploadBox}>
            <CloudUpload />
            <Typography>Click here to upload</Typography>
          </label>
        </Box>
      )}

      {images.length > 0 && (
        <>
          <Box
            mt={2}
            display={"flex"}
            alignItems="center"
            justifyContent={"space-between"}
          >
            <InputLabel>Upload Images</InputLabel>
            <Button
              onClick={() => setImages([])}
              sx={{ padding: 0, marginBottom: 1, textTransform: "capitalize" }}
              color="error"
            >
              Clear Files
            </Button>
          </Box>
          <Box className={classes.viewUploadFiles}>
            {images.map((img, index) => (
              <img
                key={index}
                src={`data:${img.type};base64,${img.data}`}
                alt="uploaded-pics"
                style={{ width: 60, height: 60, margin: 10, borderRadius: 8 }}
              />
            ))}
            <label htmlFor="review-file-uploader" className={classes.addBtn}>
              <Add fontSize="large" />
            </label>
          </Box>
        </>
      )}

      <input
        accept="image/png, image/jpeg"
        multiple
        hidden
        id="review-file-uploader"
        type="file"
        onChange={handleFileChange}
      />
    </>
  );
};

export default RatingImagesUploader;
