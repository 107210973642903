import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { connect, ConnectedProps } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  checkAuthenticate,
  logout,
} from "../../redux-store/actions/userAction";
import HeaderCitiesNavbar from "./components/header-cities-navbar/HeaderCitiesNavbar";
import SearchTopBar from "./components/search-top-bar/SearchTopBar";
import LoginSignupModal from "../modals/LoginSignupModal";
import VerifyOtpModal from "../modals/VerifyOtpModal";
// import { RootState } from "../../../redux-store/reducers";

type PropsFromRedux = ConnectedProps<typeof connector>;

interface TopNavigationProps extends PropsFromRedux {}

const Header: React.FC<TopNavigationProps> = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [UserMobile, setUserMobile] = useState("");
  let location = useLocation();
  const { checkLogin } = props;

  const [dropDown, setdropDown] = useState(false);
  const userData = props.userData;

  const ToggleModal = () => {
    setModalShow(!modalShow);
  };

  const ToggleVerifyModal = () => {
    setOtpModal(!otpModal);
  };

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  if (location.pathname === "/online-payment") {
    return null;
  }

  return (
    <>
      {location.pathname === "/search" ||
      location.pathname === `/search/nearby` ? (
        <SearchTopBar />
      ) : (
        <div className="wow slideInDown MainNavBar" id="MainNavBar">
          <div className="top-header-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-4">
                  <div className="logo-wrapper">
                    <Link to={"/"}>
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-8">
                  <div className="profile-wrapper">
                    <div className="profile">
                      {!userData.login && (
                        <Button
                          style={{ background: "none", border: "none" }}
                          onClick={() => {
                            setModalShow(true);
                          }}
                        >
                          <img
                            alt=""
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/default-user.png"
                            }
                          />
                          <span className="profile-text">Login / Signup</span>
                        </Button>
                      )}

                      {userData.login && (
                        <div className="d-inline-block">
                          <button
                            onClick={() => {
                              setdropDown(!dropDown);
                            }}
                            type="button"
                            className="header-profile-btn"
                          >
                            <img
                              alt=""
                              className="header-profile-img"
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/default-user-img.png"
                              }
                            />
                            <span className="profile-text">
                              {userData.full_name}
                            </span>
                          </button>
                          {dropDown && (
                            <ul className="profile-dropdown">
                              <li>
                                <Link
                                  onClick={() => setdropDown(false)}
                                  to="/profile"
                                >
                                  <i className="fas fa-user-circle"></i> Profile
                                </Link>
                              </li>
                              <li>
                                <Link
                                  onClick={() => setdropDown(false)}
                                  to="/profile/booking"
                                >
                                  <i className="fas fa-calendar-alt"></i>{" "}
                                  Booking
                                </Link>
                              </li>
                              <li>
                                <Link
                                  to={""}
                                  onClick={() => {
                                    props.logout();
                                  }}
                                >
                                  <i className="fas fa-power-off"></i> Logout
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                      )}

                      <Link
                        className="AddPropertybtn"
                        to={"/list-your-property"}
                      >
                        List Your Property
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <HeaderCitiesNavbar />
        </div>
      )}

      <LoginSignupModal
        show={modalShow}
        ToggleVerifyModal={ToggleVerifyModal}
        ToggleModal={ToggleModal}
      />
      <VerifyOtpModal show={otpModal} ToggleVerifyModal={ToggleVerifyModal} />
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    checkLogin: () => dispatch(checkAuthenticate()),
    logout: () => dispatch(logout()),
  };
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Header);
