import React, { useState, useEffect } from "react";
import Api from "../../services/api.service";
import { PoliciesFind } from "../../utils/helpers";

interface HotelDetails {
  policy_rules: string;
  // Add more fields if necessary based on your props.hoteldetails structure
}

interface Props {
  hoteldetails: HotelDetails;
}

interface Policy {
  id: number;
  policy: string;
  // Add more fields if necessary based on your policies object structure
}

const HotelPolicies: React.FC<Props> = (props) => {
  const [policyList, setPolicyList] = useState<Policy[]>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);

  useEffect(() => {
    getPolicies();
  }, []);

  const getPolicies = () => {
    setDataLoading(true);
    const formData = new FormData();
    Api.post("policy", formData).then((res) => {
      const data = res.data["data"];

      if (res.data["status"] === "success") {
        setPolicyList(data);
        setDataLoading(false);
      } else {
        setDataLoading(false);
      }
    });
  };

  return (
    <ul>
      {policyList.map((rules, index) => {
        const find = PoliciesFind(
          rules.id,
          JSON.parse(props.hoteldetails.policy_rules)
        );
        if (find > 0) {
          return <li key={index}>{rules.policy}</li>;
        } else {
          return null;
        }
      })}
    </ul>
  );
};

export default HotelPolicies;
