import React, { useCallback, useEffect, useRef, useState } from "react";
import { addDays } from "date-fns";
import { isMobile } from "react-device-detect";
import moment from "moment";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { DateRange, Range } from "react-date-range"; // Import Range from react-date-range
import { HotelContext } from "../../Reducers/HotelView/HotelContext";
import { CalculatePrice } from "../../helper/PriceCalculator";
import { UPDATE_ROOM_DATA } from "../../Reducers/HotelView/HotelReducer";
import RoomGuestSelectorModal from "../../container/modals/RoomGuestSelectorModal";
import LoginSignupModal from "../../container/modals/LoginSignupModal";
import VerifyOtpModal from "../../container/modals/VerifyOtpModal";
import ConfirmBookingModel from "../../container/modals/ConfirmBookingModel";
import ApplyCoupon from "./ApplyCoupon";
import { getCheckInTime } from "../../utils/helpers";

interface BookingOptionsBlockProps {
  userData: {
    login: boolean;
    // Add other relevant properties from userReducer state if needed
  };
}

const BookingOptionsBlock: React.FC<BookingOptionsBlockProps> = (props) => {
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [otpModal, setOtpModal] = useState<boolean>(false);
  const [GuestRoomPicker, setGuestRoomPicker] = useState<boolean>(false);
  const [datePicker, setdatePicker] = useState<boolean>(false);
  const dateKey = useRef<any>(); // Adjust type as needed
  const [checkindate, setcheckindate] = useState<Date>(
    getCheckInTime().toDate()
  );
  const [checkoutdate, setcheckoutdate] = useState<Date>(
    addDays(getCheckInTime().toDate(), 1)
  );
  const [BookingModalShow, setBookingModalShow] = useState<boolean>(false);
  const location = useLocation();

  const ToggleModal = () => {
    setModalShow(false);
  };
  const ToggleVerifyModal = () => {
    setOtpModal(!otpModal);
  };
  const ToggleBookingModal = () => {
    setBookingModalShow(!BookingModalShow);
  };
  const hotelContext = React.useContext(HotelContext);
  const { payablePrice, offPrice, finaldiscount, saving } = CalculatePrice(
    hotelContext?.hotelState as any
  );

  const [ranges, setranges] = useState<Range[]>([
    {
      startDate: getCheckInTime().toDate(),
      endDate: addDays(getCheckInTime().toDate(), 1),
      key: "selection",
    },
  ]);

  const saveCheckinCheckOut = (item: { selection: Range }) => {
    const dateObj = dateKey.current;
    if (dateObj == null) {
      return;
    }
    const focus = dateObj.state.focusedRange;
    if (focus[0] === 0 && focus[1] === 1) {
      changeDateRange(item, { checkindate, checkoutdate });
    }
  };

  const changeDateRange = useCallback(
    (
      item: { selection: Range },
      dates: { checkindate: Date; checkoutdate: Date }
    ) => {
      const checkin = moment(item.selection.startDate).format("YYYY-MM-DD");
      const checkout = moment(item.selection.endDate).format("YYYY-MM-DD");

      if (checkin === checkout) {
        setranges([
          {
            startDate: new Date(dates.checkindate),
            endDate: new Date(dates.checkoutdate),
            key: "selection",
          },
        ]);
        alert("Please select valid dates");
        return;
      }

      setcheckindate(new Date(checkin));
      setcheckoutdate(new Date(checkout));

      let payload = {
        checkInDate: checkin,
        checkOutDate: checkout,
        offerfinalamount: 0,
        discountedAmount: 0,
      };
      hotelContext?.hotelDispatch({
        type: UPDATE_ROOM_DATA,
        payload: payload,
      });
    },
    [hotelContext]
  );
  const setQueryCheckinCheckout = useCallback(() => {
    const params = new URLSearchParams(location.search);
    let CHECK_IN_DATE = getCheckInTime().toDate();
    let CHECK_OUT_DATE = addDays(getCheckInTime().toDate(), 1);
    if (params.get("checkin")) {
      CHECK_IN_DATE = new Date(params.get("checkin") as string);
    }
    if (params.get("checkout")) {
      CHECK_OUT_DATE = new Date(params.get("checkout") as string);
    }
    let dateRange = {
      selection: {
        startDate: CHECK_IN_DATE,
        endDate: CHECK_OUT_DATE,
        key: "selection",
      },
    };
    setranges([dateRange.selection]);
    changeDateRange(dateRange, { checkindate, checkoutdate });
  }, [changeDateRange, location.search]);

  useEffect(() => {
    setQueryCheckinCheckout();
  }, []);

  return (
    <>
      <div className="hotel-view-login-wrapper">
        {!props.userData.login && (
          <div className="hotel-view-login-header">
            <h1>Log in now to get exclusive deals</h1>
            <Link
              to={"/"}
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                setModalShow(true);
              }}
            >
              Login
            </Link>
          </div>
        )}
        <div className="hotelvilahouse-price">
          <span className="main-price">
            <i className="fas fa-rupee-sign"></i> {payablePrice}
          </span>
          <span className="off-price">
            <i className="fas fa-rupee-sign"></i> {offPrice}
          </span>
          <span className="discount-price">
            {(((offPrice - payablePrice) * 100) / offPrice).toFixed() + "% off"}{" "}
          </span>
        </div>
        <div className="room-type">
          <strong>Selected : </strong>
          {hotelContext?.hotelState.room_category}
        </div>

        <div className="DateGuestPickerBlock shadow-sm">
          <div className="datePicker">
            <p
              onClick={() => {
                setdatePicker(!datePicker);
              }}
              className="DateGuestText"
            >{`${moment(checkindate).format("ddd, D MMM  ")} - ${moment(
              checkoutdate
            ).format("ddd, D MMM  ")}`}</p>
            {datePicker && (
              <DateRange
                ref={dateKey}
                minDate={getCheckInTime().toDate()}
                className="DateRangePicker"
                onChange={(item) => {
                  setranges([item.selection]);
                  saveCheckinCheckOut(item as any);
                }}
                moveRangeOnFirstSelection={false}
                ranges={ranges}
                months={isMobile ? 1 : 2}
                direction="horizontal"
                dragSelectionEnabled={true}
                showPreview={true}
                showDateDisplay={false}
                onRangeFocusChange={(focus) => {
                  if (focus[0] === 0 && focus[1] === 0) {
                    setdatePicker(false);
                  }
                }}
              />
            )}
          </div>
          <div className="GuestRoomPicker">
            <RoomGuestSelectorModal />
          </div>
        </div>

        <ApplyCoupon />
        <div className="price-details">
          <ul>
            <li>
              <div>Your savings</div>
              <strong>
                <i className="fas fa-rupee-sign"></i> {saving}
              </strong>
            </li>
            <li>
              <div>
                Total price<span>(incl. of all taxes)</span>
              </div>
              <strong>
                <i className="fas fa-rupee-sign"></i> {payablePrice}
              </strong>
            </li>
          </ul>
        </div>

        {!props.userData.login ? (
          <Link
            to={"/"}
            style={{ cursor: "pointer" }}
            className="Continue-Book-btn"
            onClick={(e) => {
              e.preventDefault();
              setModalShow(true);
            }}
          >
            Continue to Book
          </Link>
        ) : (
          <Link
            to={"/"}
            className="Continue-Book-btn"
            onClick={(e) => {
              e.preventDefault();
              setBookingModalShow(true);
            }}
          >
            Continue to Book
          </Link>
        )}

        <div className="login-policiy">
          <Link to="/cancellation-policy">Cancellation Policy</Link>
          <Link to="/guest-policy">Guest Policies</Link>
        </div>
      </div>

      <ConfirmBookingModel
        show={BookingModalShow}
        ToggleModal={ToggleBookingModal}
      />

      <LoginSignupModal
        show={modalShow}
        ToggleVerifyModal={ToggleVerifyModal}
        ToggleModal={ToggleModal}
      />
      <VerifyOtpModal show={otpModal} ToggleVerifyModal={ToggleVerifyModal} />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  userData: state.userReducer,
});

export default connect(mapStateToProps)(BookingOptionsBlock);
