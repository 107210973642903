import moment from "moment";

interface State {
  pay_online: string;
  pay_at_hotel: string;
  partial_payment: string;
  room_id: any;
  hotel_id: any;
  vendor_id: any;
  checkInDate: string;
  checkOutDate: string;
  min_price: number;
  max_price: number;
  rooms: number;
  guest: number;
  discountedAmount: number;
  offerfinalamount: number;
  roomChargeSettings: RoomChargeSettings;
  nightChargeSettings: NightChargeSettings;
  guestChargeSettings: GuestChargeSettings;
}

interface RoomChargeSettings {
  room_2_charges: number;
  room_3_charges: number;
  room_4_charges: number;
  room_5_charges: number;
  room_6_charges: number;
}

interface NightChargeSettings {
  night_2_charges: number;
  night_4_charges: number;
  night_8_charges: number;
}

interface GuestChargeSettings {
  guest_2_charges: number;
  guest_3_charges: number;
}

interface PriceCalculationResult {
  payablePrice: number;
  offPrice: number;
  finaldiscount: number;
  finalAmount: number;
  saving: number;
  numberofRooms: number;
  numberOfGuests: number;
  numberOfNights: number;
  checkInDate: string;
  checkOutDate: string;
  state: State;
}

export const CalculatePrice = (state: State): PriceCalculationResult => {
  let payablePrice = 0;
  let offPrice = 0;
  let finaldiscount = state.discountedAmount;
  let finalAmount = state.offerfinalamount;
  let saving = 0;

  let checkInDate = moment(state.checkInDate).format("YYYY-MM-DD");
  let checkOutDate = moment(state.checkOutDate).format("YYYY-MM-DD");

  const actualPrice = state.min_price;
  const cutOffPrice = state.max_price;
  const numberofRooms = state.rooms;
  const numberOfNights = moment(state.checkOutDate).diff(
    moment(state.checkInDate),
    "days"
  );
  const numberOfGuests = state.guest;

  let priceFromRoomCal = roomCalculationPrice(
    actualPrice,
    numberofRooms,
    numberOfGuests,
    state
  );
  payablePrice = nightCalculationPrice(priceFromRoomCal, numberOfNights, state);

  offPrice = cutOffPrice * numberofRooms * numberOfNights;
  saving = offPrice - payablePrice;

  // Calculate Saving with Discount
  if (finaldiscount > 0) {
    saving =
      parseFloat(saving.toString()) + parseFloat(finaldiscount.toString());
  }
  if (finalAmount > 0) {
    payablePrice = finalAmount;
  }

  return {
    payablePrice,
    offPrice,
    finaldiscount,
    finalAmount,
    saving,
    numberofRooms,
    numberOfGuests,
    numberOfNights,
    checkInDate,
    checkOutDate,
    state,
  };
};

function roomCalculationPrice(
  actualPrice: number,
  numberOfRooms: number,
  numberOfGuests: number,
  state: State
): number {
  let roomPriceFinal = 0;
  const roomCharges = state.roomChargeSettings;
  let room2charges = roomCharges.room_2_charges / 1;
  let room3charges = roomCharges.room_3_charges / 1;
  let room4charges = roomCharges.room_4_charges / 1;
  let room5charges = roomCharges.room_5_charges / 1;
  let room6charges = roomCharges.room_6_charges / 1;

  actualPrice = parseInt(actualPrice.toString());
  if (numberOfRooms >= 1) {
    roomPriceFinal += guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      1,
      state
    );
  }
  if (numberOfRooms >= 2) {
    let offSecond = guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      2,
      state
    );
    offSecond = (offSecond * room2charges) / 100;
    roomPriceFinal += offSecond;
  }
  if (numberOfRooms >= 3) {
    // 90% off
    let offThird = guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      3,
      state
    );
    offThird = (offThird * room3charges) / 100;
    roomPriceFinal += offThird;
  }
  if (numberOfRooms >= 4) {
    // 90% off
    let offFourth = guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      4,
      state
    );
    offFourth = (offFourth * room4charges) / 100;
    roomPriceFinal += offFourth;
  }
  if (numberOfRooms >= 5) {
    // 80% off
    let offFifth = guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      5,
      state
    );
    offFifth = (offFifth * room5charges) / 100;
    roomPriceFinal += offFifth;
  }
  if (numberOfRooms >= 6) {
    // 80% off
    let offSixth = guestIncrement(
      actualPrice,
      numberOfGuests,
      numberOfRooms,
      6,
      state
    );
    offSixth = (offSixth * room6charges) / 100;
    roomPriceFinal += offSixth;
  }
  return Math.round(roomPriceFinal);
}

function nightCalculationPrice(
  price: number,
  numberOfNights: number,
  state: State
): number {
  let roomPriceFinal = 0;
  price = parseInt(price.toString());

  const nightCharges = state.nightChargeSettings;
  let night_2_charges = nightCharges.night_2_charges / 1;
  let night_4_charges = nightCharges.night_4_charges / 1;
  let night_8_charges = nightCharges.night_8_charges / 1;

  for (let night = 1; night <= numberOfNights; night++) {
    if (night > 8) {
      // 60% off
      let off = (price * night_8_charges) / 100;
      roomPriceFinal += off;
    } else if (night > 4) {
      // 80% off
      let off = (price * night_4_charges) / 100;
      roomPriceFinal += off;
    } else if (night > 2) {
      // 90% off
      let off = (price * night_2_charges) / 100;
      roomPriceFinal += off;
    } else {
      roomPriceFinal += price;
    }
  }

  return Math.round(roomPriceFinal);
}

function guestIncrement(
  price: number,
  numberOfGuests: number,
  numberOfRooms: number,
  room: number,
  state: State
): number {
  const guestCharges = state.guestChargeSettings;
  let twoGuestCharge = guestCharges.guest_2_charges / 1;
  let threeGuestCharge = guestCharges.guest_3_charges / 1;

  // 2 Fill
  if (numberOfGuests > numberOfRooms && numberOfGuests <= numberOfRooms * 2) {
    let fillRange = numberOfGuests - numberOfRooms;
    if (fillRange >= room) {
      price += (price * twoGuestCharge) / 100;
    }
  } else if (
    numberOfGuests > numberOfRooms * 2 &&
    numberOfGuests <= numberOfRooms * 3
  ) {
    let fillRange = numberOfGuests - numberOfRooms * 2;
    if (fillRange >= room) {
      price += (price * threeGuestCharge) / 100;
    } else {
      price += (price * twoGuestCharge) / 100;
    }
  }

  return Math.round(price);
}
