import React, { useRef, useState } from "react";
import { Alert, Modal } from "react-bootstrap";
import { connect } from "react-redux";
import Api from "../../services/api.service";
import { userThunkLogin } from "../../redux-store/actions/userAction";

interface ResetPasswordModalProps {
  show: boolean;
  ToggleResetModal: () => void;
  login: (token: string) => void;
}

const ResetPasswordModal: React.FC<ResetPasswordModalProps> = (props) => {
  const [FormErrors, setFormErrors] = useState<string>("");
  const [ValidationFormErrors, setValidationFormErrors] = useState<any>({});
  const [FormSuccess, setFormSuccess] = useState<string>("");
  const [FormLoading, setFormLoading] = useState<boolean>(false);

  const formRef = useRef<HTMLFormElement>(null);

  const handleResetPassSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    setFormErrors("");
    setFormSuccess("");
    setValidationFormErrors({});
    setFormLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const mobile = localStorage.getItem("usermobile");
    if (mobile) {
      formData.append("mobile", mobile);
      formData.append("pass", e.currentTarget.pass.value);
      formData.append("conf_pass", e.currentTarget.conf_pass.value);

      Api.post("/reset_password", formData)
        .then((res) => {
          setFormLoading(false);
          const data = res.data;
          if (res.data["status"] === "success") {
            localStorage.removeItem("usermobile");
            const token_id = data.token_id;
            const token = data.token;
            const usertoken = token_id + ":" + token;
            props.login(usertoken);
            props.ToggleResetModal();
          } else if (res.data["title"] === "form Error") {
            setValidationFormErrors(res.data["formErrors"]);
          } else {
            setFormErrors(res.data["msg"]);
          }
        })
        .catch((err) => {
          setFormLoading(false);
        });
    }
  };

  return (
    <>
      <Modal
        animation={true}
        centered
        className="modal-wrapper"
        dialogClassName="w-35"
        show={props.show}
        onHide={() => {
          if (formRef.current) {
            formRef.current.reset();
          }
          props.ToggleResetModal();
        }}
      >
        <div>
          <button
            type="button"
            className="close"
            onClick={() => {
              if (formRef.current) {
                formRef.current.reset();
              }
              props.ToggleResetModal();
            }}
          >
            &times;
          </button>
          <h1 className="modaltitle">Reset Password</h1>
        </div>

        {FormSuccess !== "" && <Alert variant="success">{FormSuccess}</Alert>}
        {FormErrors !== "" && <Alert variant="danger">{FormErrors}</Alert>}
        <form ref={formRef} onSubmit={handleResetPassSubmit}>
          <div className="p-3">
            <div className="form-group">
              <label>Password</label>
              <input
                className="form-control"
                type="password"
                name="pass"
                placeholder=""
              />
              <div className="invalid-feedback d-block">
                {ValidationFormErrors.pass}
              </div>
            </div>
            <div className="form-group">
              <label>Confirm Password</label>
              <input
                className="form-control"
                type="password"
                name="conf_pass"
                placeholder=""
              />
              <div className="invalid-feedback d-block">
                {ValidationFormErrors.conf_pass}
              </div>
            </div>
          </div>
          <div className="form-group submit-btn">
            <input type="submit" value="SUBMIT" />
          </div>
        </form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    login: (token: string) => dispatch(userThunkLogin(token, "user")),
  };
};

export default connect(null, mapDispatchToProps)(ResetPasswordModal);
