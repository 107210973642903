import React from "react";

interface HotelDetails {
  description: string;
}

const HotelDescription: React.FC<HotelDetails> = (props) => {
  const { description } = props;

  return (
    <div className={"pt-2 pb-3"}>
      <h2 className="details-sub-heading">Description</h2>
      <p>{description}</p>
    </div>
  );
};

export default HotelDescription;
