import React, { useEffect, useState, useCallback } from "react";
import { useLocation, useParams } from "react-router-dom";
import { FilterContext } from "../../Reducers/FilterView/FilterContext";
import {
  FILTER_REDUCER,
  initialState,
  UPDATE_FILTER,
} from "../../Reducers/FilterView/FilterReducer";
import { MinMaxPrice } from "../../helper/searchHelper";
import Api from "../../services/api.service";
import { APIURLS } from "../../services/APIURLS";
import PageLoading from "../../components/page-loading/PageLoading";
import FilterSection from "../../components/filter/FilterSection";
import FiltersResult from "../../components/filter/FiltersResult";

const HotelByCategories = () => {
  const params = useParams();
  const location = useLocation();
  const [category, setCategory] = useState<string | undefined>();
  const [dataLoading, setDataLoading] = useState<boolean>(true);
  const [state, dispatch] = React.useReducer(FILTER_REDUCER, initialState);

  const loadAmenities = useCallback(async () => {
    try {
      const formData = new FormData();
      const token = await localStorage.getItem("token");
      formData.append("token", token || "");
      const res = await Api.post(APIURLS.GETALLAMENITIES, formData);
      const responseData = res.data;
      if (responseData.status === "success") {
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            amenities: responseData.data,
          },
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getHotels = useCallback(async () => {
    const formData = new FormData();
    const token = await localStorage.getItem("token");
    formData.append("category_id", params.id ?? "");
    formData.append("token", token || "");

    let position: GeolocationCoordinates = {
      latitude: 0,
      longitude: 0,
      altitude: 0,
      accuracy: 0,
      altitudeAccuracy: 0,
      heading: 0,
      speed: 0,
    };

    navigator.geolocation.getCurrentPosition((currentPosition) => {
      position = currentPosition.coords;
    });

    try {
      const res = await Api.post(APIURLS.GETCATEGORIESROOM, formData);
      const data = res.data;
      if (data.status === "success") {
        setDataLoading(false);
        const allHotels = data.data;
        // Calculate Min Max Price
        const minMaxPrice = MinMaxPrice(allHotels);
        dispatch({
          type: UPDATE_FILTER,
          payload: {
            minPrice: Number(minMaxPrice.min),
            maxPrice: Number(minMaxPrice.max),
            priceValues: [Number(minMaxPrice.min), Number(minMaxPrice.max)],
            originalHotelsList: allHotels,
            filtersHotels: allHotels,
            position: position,
            orderByNearest: allHotels,
          },
        });
      } else {
        setDataLoading(false);
      }
    } catch (error) {
      setDataLoading(false);
      console.error(error);
    }
  }, [params.id]);

  useEffect(() => {
    loadAmenities();
    getHotels();
    setCategory(location.state?.category_name); // Assuming category_name is available in state
  }, [loadAmenities, getHotels, location.state?.category_name]);

  if (dataLoading) {
    return <PageLoading />;
  } else {
    return (
      <FilterContext.Provider
        value={{
          state: state,
          dispatch: dispatch,
        }}
      >
        <section className="whole-search-wrapper my-4">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-4 col-lg-3 filter-border-right">
                <FilterSection />
              </div>
              <div className="col-md-8 col-lg-8">
                <div className="short-filter-wrapper ">
                  <h1>{category} STAYS</h1>
                </div>
                <FiltersResult />
              </div>
            </div>
          </div>
        </section>
      </FilterContext.Provider>
    );
  }
};

export default HotelByCategories;
