import React, { createContext, Dispatch } from "react";
import { HotelAction, HotelState } from "../../Reducers/HotelView/HotelReducer";

export type HotelContextType = {
  hotelState: HotelState;
  hotelDispatch: Dispatch<HotelAction>;
};

export const HotelContext = createContext<HotelContextType | undefined>(
  undefined
);
