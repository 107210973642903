import React, { useState, FormEvent } from "react";
import { Alert, Modal } from "react-bootstrap";
import Api from "../../services/api.service";

interface ForgotModalProps {
  show: boolean;
  ToggleModal: () => void;
  ToggleVerifyModal: () => void;
}

const ForgotModal: React.FC<ForgotModalProps> = (props) => {
  const [FormErrors, setFormErrors] = useState<string>("");
  const [ValidationFormErrors, setValidationFormErrors] = useState<{
    [key: string]: string;
  }>({});
  const [FormSuccess, setFormSuccess] = useState<string>("");
  const [FormLoading, setFormLoading] = useState<boolean>(false);

  const handleForgotSubmit = (e: FormEvent<HTMLFormElement>) => {
    setFormErrors("");
    setFormSuccess("");
    setValidationFormErrors({});
    setFormLoading(true);
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const mobile = e.currentTarget.mobile.value;

    formData.append("mobile", mobile);
    Api.post("forgot_password/forgot_user", formData)
      .then((res) => {
        setFormLoading(false);
        if (res.data["status"] === "success") {
          localStorage.setItem("usermobile", mobile);
          props.ToggleModal();
          props.ToggleVerifyModal();
        } else if (res.data["title"] === "form Error") {
          setValidationFormErrors(res.data["formErrors"]);
        } else {
          setFormErrors(res.data["msg"]);
        }
      })
      .catch(() => {
        setFormLoading(false);
      });
  };

  return (
    <Modal
      animation={true}
      centered
      className="modal-wrapper"
      dialogClassName="w-35"
      show={props.show}
      onHide={props.ToggleModal}
    >
      <div>
        <button type="button" className="close" onClick={props.ToggleModal}>
          &times;
        </button>
        <h1 className="modaltitle">Forgot Password</h1>
      </div>

      {FormSuccess && <Alert variant="success">{FormSuccess}</Alert>}
      {FormErrors && <Alert variant="danger">{FormErrors}</Alert>}
      <form onSubmit={handleForgotSubmit}>
        <div className="p-3">
          <div className="form-group">
            <label>Email & number</label>
            <input
              className="form-control"
              type="text"
              name="mobile"
              placeholder=""
            />
            <div className="invalid-feedback d-block">
              {ValidationFormErrors.mobile}
            </div>
          </div>
        </div>
        <div className="form-group submit-btn">
          <input type="submit" value="SUBMIT" />
        </div>
      </form>
    </Modal>
  );
};

export default ForgotModal;
