export const UPDATE_FILTER = "UPDATE_FILTER";
export const RESET_FILTER = "RESET_FILTER";

export interface State {
  originalHotelsList: any[];
  filtersHotels: any[];
  amenities: any[];
  selectedAmenities: any[];
  sortBy: string;
  minPrice: number;
  maxPrice: number;
  priceValues: [number, number];
  position: Record<string, any>;
  orderByNearest: any[];
  guestRating: boolean;
  payAtHotel: boolean;
  categories: any[];
  selectedCategories: any[];
}

export const initialState: State = {
  originalHotelsList: [],
  filtersHotels: [],
  amenities: [],
  selectedAmenities: [],
  sortBy: "popularity",
  minPrice: 0,
  maxPrice: 0,
  priceValues: [0, 0],
  position: {},
  orderByNearest: [],
  guestRating: false,
  payAtHotel: false,
  categories: [],
  selectedCategories: [],
};

interface Action {
  type: string;
  payload?: Partial<State>;
}

export const FILTER_REDUCER = (state: State, action: Action): State => {
  switch (action.type) {
    case UPDATE_FILTER:
      return {
        ...state,
        ...action.payload,
      };
    case RESET_FILTER:
      return initialState;
    default:
      return state;
  }
};
